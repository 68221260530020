/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BodyRunCorrelationFinderApiCorrelationFinderRunPost,
  CorrelationFinderRequest,
  CorrelationFinderResults,
  HTTPValidationError,
  RunCorrelationFinderParams
} from '.././model'



/**
 * @summary Run Correlation Finder
 */
export const runCorrelationFinder = (
    bodyRunCorrelationFinderApiCorrelationFinderRunPost: BodyRunCorrelationFinderApiCorrelationFinderRunPost,
    params?: RunCorrelationFinderParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CorrelationFinderRequest>> => {
    
    return axios.default.post(
      `/api/correlation_finder/run`,
      bodyRunCorrelationFinderApiCorrelationFinderRunPost,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRunCorrelationFinderMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runCorrelationFinder>>, TError,{data: BodyRunCorrelationFinderApiCorrelationFinderRunPost;params?: RunCorrelationFinderParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof runCorrelationFinder>>, TError,{data: BodyRunCorrelationFinderApiCorrelationFinderRunPost;params?: RunCorrelationFinderParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runCorrelationFinder>>, {data: BodyRunCorrelationFinderApiCorrelationFinderRunPost;params?: RunCorrelationFinderParams}> = (props) => {
          const {data,params} = props ?? {};

          return  runCorrelationFinder(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunCorrelationFinderMutationResult = NonNullable<Awaited<ReturnType<typeof runCorrelationFinder>>>
    export type RunCorrelationFinderMutationBody = BodyRunCorrelationFinderApiCorrelationFinderRunPost
    export type RunCorrelationFinderMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Run Correlation Finder
 */
export const useRunCorrelationFinder = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runCorrelationFinder>>, TError,{data: BodyRunCorrelationFinderApiCorrelationFinderRunPost;params?: RunCorrelationFinderParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof runCorrelationFinder>>,
        TError,
        {data: BodyRunCorrelationFinderApiCorrelationFinderRunPost;params?: RunCorrelationFinderParams},
        TContext
      > => {

      const mutationOptions = getRunCorrelationFinderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Correlation Finder Request
 */
export const getCorrelationFinderRequest = (
    requestId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CorrelationFinderRequest>> => {
    
    return axios.default.get(
      `/api/correlation_finder/status/${requestId}`,options
    );
  }


export const getGetCorrelationFinderRequestQueryKey = (requestId: number,) => {
    return [`/api/correlation_finder/status/${requestId}`] as const;
    }

    
export const getGetCorrelationFinderRequestQueryOptions = <TData = Awaited<ReturnType<typeof getCorrelationFinderRequest>>, TError = AxiosError<HTTPValidationError>>(requestId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderRequest>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCorrelationFinderRequestQueryKey(requestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCorrelationFinderRequest>>> = ({ signal }) => getCorrelationFinderRequest(requestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(requestId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderRequest>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCorrelationFinderRequestQueryResult = NonNullable<Awaited<ReturnType<typeof getCorrelationFinderRequest>>>
export type GetCorrelationFinderRequestQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Correlation Finder Request
 */
export const useGetCorrelationFinderRequest = <TData = Awaited<ReturnType<typeof getCorrelationFinderRequest>>, TError = AxiosError<HTTPValidationError>>(
 requestId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderRequest>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCorrelationFinderRequestQueryOptions(requestId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get Correlation Finder Results
 */
export const getCorrelationFinderResults = (
    requestId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CorrelationFinderResults>> => {
    
    return axios.default.get(
      `/api/correlation_finder/result/${requestId}`,options
    );
  }


export const getGetCorrelationFinderResultsQueryKey = (requestId: number,) => {
    return [`/api/correlation_finder/result/${requestId}`] as const;
    }

    
export const getGetCorrelationFinderResultsQueryOptions = <TData = Awaited<ReturnType<typeof getCorrelationFinderResults>>, TError = AxiosError<HTTPValidationError>>(requestId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderResults>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCorrelationFinderResultsQueryKey(requestId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCorrelationFinderResults>>> = ({ signal }) => getCorrelationFinderResults(requestId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(requestId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderResults>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCorrelationFinderResultsQueryResult = NonNullable<Awaited<ReturnType<typeof getCorrelationFinderResults>>>
export type GetCorrelationFinderResultsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Get Correlation Finder Results
 */
export const useGetCorrelationFinderResults = <TData = Awaited<ReturnType<typeof getCorrelationFinderResults>>, TError = AxiosError<HTTPValidationError>>(
 requestId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCorrelationFinderResults>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCorrelationFinderResultsQueryOptions(requestId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



