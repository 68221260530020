import AbcRoundedIcon from '@mui/icons-material/AbcRounded'
import CategoryIcon from '@mui/icons-material/Category'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import EngineeringIcon from '@mui/icons-material/Engineering'
import FactoryIcon from '@mui/icons-material/Factory'
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded'
import WarehouseIcon from '@mui/icons-material/Warehouse'

export default function getSearchTagIcon(key: string) {
  switch (key) {
    case 'Type':
      return <DataObjectRoundedIcon />
    case 'Name':
      return <AbcRoundedIcon />
    case 'Table':
      return <TableChartRoundedIcon />
    case 'Material':
      return <CategoryIcon />
    case 'Domain':
      return <FactoryIcon />
    case 'Process':
      return <PrecisionManufacturingIcon />
    case 'Area':
      return <WarehouseIcon />
    case 'Author':
      return <EngineeringIcon />
    case 'Cell Kind':
      return <DirectionsCarIcon />
    default:
      return <LocalOfferRoundedIcon />
  }
}
