enum ProcessType {
  // ANODE_COATING = 'Anode Coating',
  // CATHODE_COATING = 'Cathode Coating',
  // ANODE_PRESSING = 'Anode Pressing',
  // CATHODE_PRESSING = 'Cathode Pressing',
  // ANODE_CALENDERING = 'Anode Calendering',
  // CATHODE_CALENDERING = 'Cathode Calendering',
  // ANODE_SHEET_CUTTING = 'Anode Sheet Cutting',
  // CATHODE_SHEET_CUTTING = 'Cathode Sheet Cutting',
  // ANODE_NOTCHING = 'Anode Notching',
  // CATHODE_NOTCHING = 'Cathode Notching',
  // SHEET_STACKING = 'Sheet Stacking',
  // HOT_PRESS = 'Hot Press',
  // PRE_WELD = 'Pre-Weld',
  // XRJ = 'XRJ',
  FINAL_WELD = 'Final Weld',
  INSULATION_FILM_WRAPPING = 'Insulation Film Wrapping',
  CELL_TO_CAN_INSERTION = 'Cell To Can Insertion',
  CELL_WELDED_TO_CAN = 'Cell Welded To Can',
  XRI = 'XRI',
  XRC = 'XRC',
  HELIUM_LEAK_1 = 'Helium Leak #1',
  EFILL_1 = 'Efill #1',
  SOAKING = 'Soaking',
  COOLING_1 = 'Cooling 1',
  NAIL_PULLING = 'Nail Pulling',
  PRE_CHARGING = 'Pre-charging',
  WIPING = 'Wiping',
  NAIL_INSERTION = 'Nail Insertion',
  EFILL_2 = 'Efill #2',
  IR_OCV_1 = 'IR/OCV 1',
  HT_AGING_1 = 'HT Aging 1',
  RT_AGING_1 = 'RT Aging 1',
  IR_OCV_2 = 'IR/OCV 2',
  FORMATION_1 = 'Formation 1',
  COOLING_2 = 'Cooling 2',
  IR_OCV_3 = 'IR/OCV 3',
  HT_AGING_2 = 'HT Aging 2',
  RT_AGING_2 = 'RT Aging 2',
  IR_OCV_4 = 'IR/OCV 4',
  DEGASSING = 'Degassing',
  FINAL_SEALING = 'Final Sealing',
  FORMATION_2 = 'Formation 2',
  DCIR = 'DCIR',
  CELL_CLEANING = 'Cell Cleaning',
  WRAPPING = 'Wrapping',
  FINAL_INSPECTION = 'Final Inspection',
  // VISION_INSPECTION = 'Vision Inspection',
  // MANUAL_INSPECTION = 'Manual Inspection',
  // DMC_LABELLING = 'DMC Labelling',
  // PACKAGING = 'Packaging',
}

enum CellType {
  PPE_CV = 'PPE CV',
  PPE_SLIM = 'PPE SLIM',
  PPE_HIGH = 'PPE HIGH',
  EV32 = 'EV32',
  GPA = 'GPA',
}

export { CellType, ProcessType }
