import { Card, CardContent, Typography } from '@northvolt/ui'

interface ErrorCardProps {
  title: string
  message: string
}

const ErrorCard: React.FC<ErrorCardProps> = ({ title, message }) => {
  return (
    <Card sx={{ height: 400, p: 2, display: 'flex', flexDirection: 'column' }}>
      <CardContent>
        <Typography variant='headlineSmall' title={title}>
          {title}
        </Typography>
        <Typography variant='bodyMedium'>{message}</Typography>
      </CardContent>
    </Card>
  )
}

export default ErrorCard
