import { Grid } from '@mui/material'
import { Button, TextField } from '@northvolt/ui'
import { useCreateAttributeCollection } from 'client/atlas/atlas'
import {
  type Attribute,
  type AttributeCollectionCreate,
  type AttributeCollectionLight,
  MaterialType,
} from 'client/model'
import GrainSelector from 'components/DataLoader/GrainSelector'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AttributeSearchAndSelect from '../Attribute/AttributeSearchAndSelect'

interface NewAttributeCollectionProps {
  attributeCollections?: AttributeCollectionLight[]
  setAttributeCollections?: (
    attributeCollections: AttributeCollectionLight[],
  ) => void
  openOnCreate?: boolean
}

const NewAttributeCollection: React.FC<NewAttributeCollectionProps> = ({
  attributeCollections,
  setAttributeCollections,
  openOnCreate,
}) => {
  const navigate = useNavigate()
  const [attributeCollectionCreate, setAttributeCollectionCreate] =
    useState<AttributeCollectionCreate>({
      name: '',
      grain: MaterialType.Prismatic_Cell,
    })
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([])
  const attrCollectionMutation = useCreateAttributeCollection()
  const [created, setCreated] = useState<boolean>(false)

  const canSubmit =
    !attributeCollectionCreate.grain ||
    !attributeCollectionCreate ||
    attributeCollectionCreate.name === '' ||
    !attributeCollectionCreate.description ||
    !(attributeCollectionCreate.attribute_ids
      ? attributeCollectionCreate.attribute_ids.length > 0
      : false)

  useEffect(() => {
    if (!created && attrCollectionMutation.isSuccess) {
      if (openOnCreate) {
        navigate(
          `/atlas/attribute-collections/${attrCollectionMutation.data.data.id}`,
        )
      } else if (attributeCollections && setAttributeCollections) {
        setAttributeCollections([
          ...attributeCollections,
          attrCollectionMutation.data.data,
        ])
      }
      setCreated(true)
    }
  }, [attrCollectionMutation.status])

  useEffect(() => {
    setAttributeCollectionCreate({
      ...attributeCollectionCreate,
      attribute_ids: selectedAttributes.map(attr => attr.id),
    })
  }, [selectedAttributes])

  useEffect(() => {
    if (created) {
      setAttributeCollectionCreate({
        name: '',
        grain: MaterialType.Prismatic_Cell,
      })
      setSelectedAttributes([])
      setCreated(false)
    }
  }, [created])

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={6}>
        <GrainSelector
          grain={attributeCollectionCreate?.grain}
          setGrain={grain =>
            setAttributeCollectionCreate({
              ...attributeCollectionCreate,
              grain: grain,
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Name'
          size='small'
          autoComplete='off'
          fullWidth
          required
          value={
            attributeCollectionCreate?.name
              ? attributeCollectionCreate.name
              : ''
          }
          onChange={e =>
            setAttributeCollectionCreate({
              ...attributeCollectionCreate,
              name: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Description'
          size='small'
          multiline
          autoComplete='off'
          rows={4}
          required
          value={
            attributeCollectionCreate?.description
              ? attributeCollectionCreate.description
              : ''
          }
          onChange={e =>
            setAttributeCollectionCreate({
              ...attributeCollectionCreate,
              description: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <AttributeSearchAndSelect
          label='Search for Attributes'
          selected={selectedAttributes}
          setSelected={setSelectedAttributes}
          grain={attributeCollectionCreate.grain}
          multiple
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          title={'Create Collection'}
          disabled={canSubmit}
          onClick={() => {
            attrCollectionMutation.mutate({ data: attributeCollectionCreate })
          }}>
          Create Collection
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewAttributeCollection
