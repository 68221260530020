import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {
  Box,
  Chip,
  Grid,
  ListSubheader,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@northvolt/ui'
import type React from 'react'
import { useEffect, useReducer, useRef, useState } from 'react'

import BlurOnIcon from '@mui/icons-material/BlurOn'
import DownloadIcon from '@mui/icons-material/Download'
import Grid4x4Icon from '@mui/icons-material/Grid4x4'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InsightsIcon from '@mui/icons-material/Insights'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import TableChartIcon from '@mui/icons-material/TableChart'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'

import * as vg from '@uwdata/vgplot'

import type { Table } from 'apache-arrow'
import { tableToIPC } from 'apache-arrow'
import AttributeSelector from 'components/Attribute/AttributeSelector'
import DataLoader from 'components/DataLoader/DataLoader'
import type {
  SelectableAttribute,
  SelectableAttributeCollection,
} from 'components/DataLoader/DataLoaderTypes'
import Plot1D from './Plot1D'
import Plot2D from './Plot2D'

import { MaterialType } from 'client/model'
import { getAttributePrompts } from 'components/Attribute/Prompts'
import AskGigaWattson from 'components/GigaWattson/AskGigaWattson'
import { DataType } from 'components/Utils'
import { downloadCSV } from './CsvDownloader'
import TableView from './TableView'

const plotTypeOptions = [
  { label: '1D Plots', type: 'subheader' },
  { label: 'Distribution', icon: <LeaderboardIcon />, type: 'item' },
  { label: '2D Plots', type: 'subheader' },
  { label: 'Scatter', icon: <ScatterPlotIcon />, type: 'item' },
  { label: 'Hexgrid', icon: <BlurOnIcon />, type: 'item' },
  { label: 'Other', type: 'subheader' },
  { label: 'Table', icon: <TableChartIcon />, type: 'item' },
]

export default function Page() {
  const theme = useTheme()
  const [renderId, forceUpdate] = useReducer(x => x + 1, 0)
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [attributeCollections, setAttributeCollections] = useState<
    SelectableAttributeCollection[]
  >([])
  const [table, setTable] = useState<Table | undefined>(undefined)
  const [dataSelectionExpanded, setDataSelectionExpanded] =
    useState<boolean>(true)

  const [selectedX, setSelectedX] = useState<SelectableAttribute | undefined>(
    undefined,
  )
  const [selectedY, setSelectedY] = useState<SelectableAttribute | undefined>(
    undefined,
  )
  const [selectedColor, setSelectedColor] = useState<
    SelectableAttribute | undefined
  >(undefined)
  const [selectedSize, setSelectedSize] = useState<
    SelectableAttribute | undefined
  >(undefined)
  const [selectedSymbol, setSelectedSymbol] = useState<
    SelectableAttribute | undefined
  >(undefined)
  const [numSelectedAttrs, setNumSelectedAttrs] = useState<number>(0)
  const [numSelectedBigPlots, setNumSelectedBigPlots] = useState<number>(0)
  const [plotTool, setPlotTool] = useState<string>('select')
  const [plotTypes, setPlotTypes] = useState<string[]>(['Distribution'])
  const [lockAxis, setLockAxis] = useState<boolean>(true)
  const [plotAreaWidth, setPlotAreaWidth] = useState<number>(600)
  const [plotAreaHeight, setPlotAreaHeight] = useState<number>(600)

  const $panZoomX = useRef<any>(null)
  const $panZoomY = useRef<any>(null)
  const $filterParam = useRef<any>(null)

  if (!$panZoomX.current) {
    $panZoomX.current = vg.Selection.intersect()
  }
  if (!$panZoomY.current) {
    $panZoomY.current = vg.Selection.intersect()
  }
  if (!$filterParam.current) {
    $filterParam.current = vg.Selection.crossfilter()
  }

  const resetPlotInteractions = () => {
    if ($panZoomX.current) {
      $panZoomX.current._value = []
      $panZoomX.current._resolved = []
    }
    if ($panZoomY.current) {
      $panZoomY.current._value = []
      $panZoomY.current._resolved = []
    }
    if ($filterParam.current) {
      $filterParam.current._value = []
      $filterParam.current._resolved = []
    }
    forceUpdate()
  }

  const plotContainerRef = useRef<any>(null)

  const handlePlotTool = (
    _: React.MouseEvent<HTMLElement>,
    newPlotTool: string | null,
  ) => {
    if (newPlotTool !== null) {
      setPlotTool(newPlotTool)
    }
  }

  useEffect(() => {
    if (table && table.numRows > 0) {
      const duckdb = vg.wasmConnector()
      duckdb.getConnection().then((con: any) => {
        const ipc = tableToIPC(table)
        con.insertArrowFromIPCStream(ipc, { name: 'active_table' }).then(() => {
          setDataSelectionExpanded(false)
        })
        vg.coordinator().databaseConnector(duckdb)
        vg.coordinator().exec([vg.loadExtension('icu')])
        vg.coordinator().clear({ cache: true, client: false })
        forceUpdate()
      })
    }
  }, [table])

  useEffect(() => {
    if (plotContainerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (plotContainerRef.current.offsetWidth !== plotAreaWidth) {
          // use functional update as the new width depends on the current width
          // this is to avoid re-rendering the entire component tree
          // the new width might have been set by another observer
          setPlotAreaWidth(currentWidth => {
            if (plotContainerRef.current.offsetWidth > currentWidth) {
              return plotContainerRef.current.offsetWidth
            }
            return currentWidth
          })
        }

        // use functional update as the new height depends on the current height
        // this is to avoid re-rendering the entire component tree
        // the new height might have been set by another observer
        // this functional state change make sure we can compare the absolut current height
        // not just what is saved far away up top
        if (plotContainerRef.current.offsetHeight !== plotAreaHeight) {
          if (plotContainerRef.current.offsetHeight > plotAreaHeight) {
            setPlotAreaHeight(currentHeight => {
              if (plotContainerRef.current.offsetHeight > currentHeight) {
                return plotContainerRef.current.offsetHeight
              }
              return currentHeight
            })
          }
        }
      })
      resizeObserver.observe(plotContainerRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [plotContainerRef.current])

  useEffect(() => {
    const numSelected = [
      selectedX,
      selectedY,
      selectedColor,
      selectedSize,
      selectedSymbol,
    ].filter(v => v !== undefined).length
    setNumSelectedAttrs(numSelected)
    resetPlotInteractions()
  }, [selectedX, selectedY, selectedColor, selectedSize, selectedSymbol])

  function handlePlotTypeChange(event: any) {
    // Allow all selected plot types, but if both scatter and hexgrid are selected, only keep the newly selected one of them:
    let newPlotTypes = event.target.value
    if (newPlotTypes.includes('Scatter') && newPlotTypes.includes('Hexgrid')) {
      const previous2D = plotTypes.find(
        (v: string) => v === 'Scatter' || v === 'Hexgrid',
      )
      newPlotTypes = newPlotTypes.filter((v: string) => v !== previous2D)
    }
    setNumSelectedBigPlots(
      newPlotTypes.filter(
        (v: string) => v === 'Scatter' || v === 'Hexgrid' || v === 'Table',
      ).length,
    )
    setPlotTypes(newPlotTypes)
    resetPlotInteractions()
  }

  function getTableColumns(): string[] {
    const selectedAttributes: (SelectableAttribute | undefined)[] = [
      selectedX,
      selectedY,
      selectedColor,
      selectedSize,
      selectedSymbol,
    ]
    const grain: MaterialType =
      selectedAttributes.find(v => v?.grain !== undefined)?.grain ??
      MaterialType.Any
    const coreCols = attributeCollections
      .filter(v => [`${grain} Core`, 'sample'].includes(v.name))
      .flatMap(v => v.attributes)
      .map(c => c?.unique_name)

    const selectedCols = selectedAttributes
      .map(c => c?.unique_name)
      .filter(v => !coreCols.includes(v))

    return coreCols.concat(selectedCols).filter((col): col is string => !!col)
  }

  function has2DPlot() {
    return plotTypes.includes('Scatter') || plotTypes.includes('Hexgrid')
  }

  function hasOtherPlot() {
    return plotTypes.includes('Table')
  }

  function hasSmallPlot() {
    return plotTypes.includes('Distribution')
  }

  function getBigWidth() {
    if (hasSmallPlot()) {
      return (3 * plotAreaWidth) / 4
    }
    return plotAreaWidth
  }

  function getSmallWidth() {
    if (has2DPlot() || hasOtherPlot()) {
      return plotAreaWidth / 4
    }
    return plotAreaWidth
  }

  function getPrompts() {
    const prompts = [
      selectedX,
      selectedY,
      selectedColor,
      selectedSize,
      selectedSymbol,
    ]
      .flatMap(attr =>
        attr === undefined ? [] : getAttributePrompts(attr, true),
      )
      .sort((a, b) => a.label.localeCompare(b.label))
    return prompts
  }

  const plotControls = (
    <Box
      sx={{ overflowY: 'scroll', overflowX: 'hidden', height: '100%', py: 1 }}>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 4, md: 2 }}>
        <Grid xs={2}>
          <FormControl fullWidth sx={{ width: '100%' }}>
            <InputLabel>Plot Type</InputLabel>
            <Select
              id='plot-type'
              label='Plot Type'
              labelId='plot-type-label'
              size='small'
              value={plotTypes}
              onChange={handlePlotTypeChange}
              multiple
              renderValue={selected =>
                selected.map(value => (
                  <Chip
                    key={value}
                    label={value}
                    icon={plotTypeOptions.find(v => v.label === value)?.icon}
                    variant='outlined'
                    size='small'
                    sx={{ m: 0.25 }}
                  />
                ))
              }>
              {plotTypeOptions.map(v =>
                v.type === 'subheader' ? (
                  <ListSubheader key={v.label}>{v.label}</ListSubheader>
                ) : (
                  <MenuItem key={v.label} value={v.label}>
                    {v.icon}
                    {v.label}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='X Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedX ? [selectedX] : []}
            setSelectedAttributes={attr => setSelectedX(attr[0])}
            disabled={table?.numRows === 0}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Y Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedY ? [selectedY] : []}
            setSelectedAttributes={attr => setSelectedY(attr[0])}
            disabled={table?.numRows === 0}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Color Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedColor ? [selectedColor] : []}
            setSelectedAttributes={attr => setSelectedColor(attr[0])}
            disabled={table?.numRows === 0}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Size Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedSize ? [selectedSize] : []}
            setSelectedAttributes={attr => setSelectedSize(attr[0])}
            disabled={table?.numRows === 0}
          />
        </Grid>
        <Grid xs={2}>
          <AttributeSelector
            label='Symbol Attribute'
            attributeCollections={attributeCollections}
            selectedAttributes={selectedSymbol ? [selectedSymbol] : []}
            setSelectedAttributes={attr => setSelectedSymbol(attr[0])}
            disabled={table?.numRows === 0}
            dataTypeWhitelist={[DataType.STRING, DataType.BOOLEAN]}
          />
        </Grid>
        <Grid xs={2}>
          <ToggleButtonGroup
            value={plotTool}
            exclusive
            size='small'
            onChange={handlePlotTool}
            title='Plot Tool'
            orientation={isSm ? 'vertical' : 'horizontal'}
            fullWidth>
            <ToggleButton value='panzoom'>
              <ZoomOutMapIcon />
              <Typography variant='captionSmall' sx={{ pl: 1 }}>
                Pan/Zoom
              </Typography>
            </ToggleButton>
            <ToggleButton value='select'>
              <HighlightAltIcon />
              <Typography variant='captionSmall' sx={{ pl: 1 }}>
                Select
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid xs={1}>
          <ToggleButton
            value='check'
            selected={lockAxis}
            onClick={() => setLockAxis(!lockAxis)}
            size='small'
            fullWidth>
            <Grid4x4Icon />
            <Typography variant='captionSmall' sx={{ pl: 1 }}>
              Lock Axis
            </Typography>
          </ToggleButton>
        </Grid>
        <Grid xs={1}>
          <ToggleButton
            value='reset'
            onClick={resetPlotInteractions}
            size='small'
            fullWidth>
            <HighlightOffIcon />
            <Typography variant='captionSmall' sx={{ pl: 1 }}>
              Reset
            </Typography>
          </ToggleButton>
        </Grid>
        <Grid xs={2}>
          <ToggleButton
            value='csv'
            onClick={() => table && downloadCSV(table, 'verona.csv')}
            size='small'
            fullWidth>
            <DownloadIcon />
            <Typography variant='captionSmall'>Download CSV</Typography>
          </ToggleButton>
        </Grid>
        <Grid xs={2}>
          <AskGigaWattson prompts={getPrompts()} fullWidth />
        </Grid>
      </Grid>
    </Box>
  )

  /* this simple hack just call these badboys once instead of on every plot */
  const smallWidth = getSmallWidth()
  const bigWidth = getBigWidth()
  const tables = getTableColumns()
  const checkPlot2d = has2DPlot()
  const checkOtherPlot = hasOtherPlot()
  const smallPlot = hasSmallPlot()
  const someSmallSize = plotAreaHeight / numSelectedAttrs
  const someBigSize = plotAreaHeight / numSelectedBigPlots

  /*
    Todo separate control from the same view as the plots to give react a chance
    to avoid re-rendering the entire state of this gigantic file.
  */
  return (
    <Stack spacing={2} display='flex' sx={{ height: '100%' }}>
      <DataLoader
        title={'Plotter'}
        table={table}
        setTable={setTable}
        setAttributeCollections={setAttributeCollections}
        expanded={dataSelectionExpanded}
        setExpanded={setDataSelectionExpanded}
        icon={<InsightsIcon />}
      />

      {/* this is the place where the juggle dance of death lives.   */}
      {(table?.numRows ?? 0) > 0 && (
        <Paper
          elevation={1}
          sx={{
            p: 2,
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row-reverse' }}
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ width: { xs: '100%', md: '30%', height: '100%' } }}>
              {plotControls}
            </Box>
            <Box sx={{ width: '100%', height: '100%' }} ref={plotContainerRef}>
              <Stack direction='row' sx={{ width: '100%', height: '100%' }}>
                {(checkPlot2d || checkOtherPlot) && (
                  <Box>
                    {checkPlot2d && (
                      <Plot2D
                        attributeX={selectedX}
                        attributeY={selectedY}
                        attributeColor={selectedColor}
                        attributeSize={selectedSize}
                        attributeSymbol={selectedSymbol}
                        plotTypes={plotTypes}
                        plotTool={plotTool}
                        lockAxis={lockAxis}
                        width={bigWidth}
                        height={someBigSize}
                        $filterParam={$filterParam.current}
                        $panZoomX={$panZoomX.current}
                        $panZoomY={$panZoomY.current}
                        renderId={renderId}
                      />
                    )}
                    {checkOtherPlot && (
                      <TableView
                        columns={tables}
                        width={bigWidth}
                        height={someBigSize}
                        $filterParam={$filterParam.current}
                        renderId={renderId}
                      />
                    )}
                  </Box>
                )}
                {smallPlot && (
                  <Box>
                    {selectedX && (
                      <Plot1D
                        attribute={selectedX}
                        width={smallWidth}
                        height={someSmallSize}
                        $filterParam={$filterParam.current}
                        renderId={renderId}
                      />
                    )}
                    {selectedY && (
                      <Plot1D
                        attribute={selectedY}
                        width={smallWidth}
                        height={someSmallSize}
                        $filterParam={$filterParam.current}
                        renderId={renderId}
                      />
                    )}
                    {selectedColor && (
                      <Plot1D
                        attribute={selectedColor}
                        width={smallWidth}
                        height={someSmallSize}
                        $filterParam={$filterParam.current}
                        isColorAttr={true}
                        renderId={renderId}
                      />
                    )}
                    {selectedSize && (
                      <Plot1D
                        attribute={selectedSize}
                        width={smallWidth}
                        height={someSmallSize}
                        $filterParam={$filterParam.current}
                        isSizeAttr={true}
                        renderId={renderId}
                      />
                    )}
                    {selectedSymbol && (
                      <Plot1D
                        attribute={selectedSymbol}
                        width={smallWidth}
                        height={someSmallSize}
                        $filterParam={$filterParam.current}
                        isSizeAttr={true}
                        renderId={renderId}
                      />
                    )}
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Paper>
      )}
    </Stack>
  )
}
