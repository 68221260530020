/**
 * Debounce
 * Creates a debounced function that delays invoking the provided function until after the specified delay.
 *
 * @param func - The function to debounce.
 * @param delay - The number of milliseconds to delay.
 * @returns A new debounced function.
 *
 * @example
 * ```typescript
 * const debouncedFunction = debounce(() => {
 *   console.log('Function executed after delay');
 * }, 300);
 *
 * window.addEventListener('resize', debouncedFunction);
 * ```
 */
export default (func: (...args: any[]) => void, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func(...args), delay)
  }
}
