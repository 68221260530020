/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

export type ExecutionStatus = typeof ExecutionStatus[keyof typeof ExecutionStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExecutionStatus = {
  RUNNING: 'RUNNING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;
