import { Box, List, ListItem, Stack, Typography } from '@northvolt/ui'
import type { Attribute, SearchTag } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import AddToCollectionPopover from '../AttributeCollection/AddToCollectionPopover'
import SearchTagChip from '../SearchTag/SearchTagChip'

interface AttributeListItemProps {
  attribute: Attribute
  clickHandler?: (searchTag: SearchTag) => void
  minimal?: boolean
}

const AttributeListItem: React.FC<AttributeListItemProps> = ({
  attribute,
  clickHandler,
  minimal = false,
}) => {
  return (
    <ListItem key={attribute.id} divider sx={{ px: 1, py: 1 }}>
      <Stack alignItems='left' sx={{ width: '100%' }}>
        <Link
          to={`/atlas/attributes/${attribute.id}`}
          style={{ color: 'inherit' }}>
          <Typography title={attribute.column_name} variant='subtitleMedium'>
            <strong>{attribute.column_name}</strong>
          </Typography>
        </Link>
        <Typography variant='captionSmall' color='text.secondary'>
          {attribute.column_description}
        </Typography>
        <Typography
          variant='captionSmall'
          color='text.secondary'
          title={attribute.qualified_table_name}>
          {attribute.qualified_table_name}
        </Typography>
        {!minimal && (
          <>
            <Typography
              variant='captionSmall'
              color='text.secondary'
              title={attribute.qualified_table_name}>
              Tags:
            </Typography>
            <List sx={{ maxHeight: 75, overflowY: 'auto', p: 1, mx: 1 }}>
              {attribute.search_tags
                ?.sort((a, b) => a.key.localeCompare(b.key))
                .map((tag, index) => (
                  <SearchTagChip
                    key={index}
                    searchTag={tag}
                    clickHandler={clickHandler}
                  />
                ))}
            </List>
          </>
        )}
      </Stack>
      <Box sx={{ flexGrow: 1, flexDirection: 'row' }} />
      <AddToCollectionPopover attribute={attribute} iconButton />
    </ListItem>
  )
}

export default AttributeListItem
