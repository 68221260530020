import { CircularProgress, Grid } from '@northvolt/ui'

export default () => (
  <Grid
    container
    spacing={0}
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={{ minHeight: '100vh' }}>
    <CircularProgress
      sx={{
        alignSelf: 'center',
        marginBottom: 3,
        textAlign: 'center',
        color: '#44a27a',
      }}
      size={64}
    />
  </Grid>
)
