import { Box, Typography } from '@northvolt/ui'

export default function Route() {
  return (
    <Box
      sx={{ width: '100%', height: '50%' }}
      alignItems='center'
      justifyContent='center'
      display='flex'>
      <Typography variant='headlineMedium'>
        turbo-fishbones is Coming Soon™
      </Typography>
    </Box>
  )
}
