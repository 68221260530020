import { Paper, Typography } from '@northvolt/ui'
import NewSample from 'components/Sample/NewSample'

export default function Page() {
  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>Create New Sample</Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        A <i>sample</i> is a set of filter conditions that together specify a
        sub-population of items (e.g. cells). Together with an{' '}
        <i>attribute collection</i>, a <i>sample</i> forms a <i>dataset</i>.
      </Typography>
      <NewSample openOnCreate={true} />
    </Paper>
  )
}
