import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Loader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@northvolt/ui'
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import {
  useDeleteSample,
  useIsSampleOwnedByUser,
  useReadSample,
} from 'client/atlas/atlas'
import ErrorCard from 'components/ErrorCard'
import FilterChip from 'components/Filter/FilterChip'
import { dateStringDisplayFormat } from 'components/Utils'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

export default function Route() {
  const { sampleId } = useParams()
  const idNumber = Number.parseInt(sampleId || '', 10) || 0

  const navigate = useNavigate()

  const sampleLoader = useReadSample(idNumber, { query: { retry: 1 } })
  const isUserOwner = useIsSampleOwnedByUser(idNumber)

  const sampleDeleteMutation = useDeleteSample()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false)

  const handleDelete = () => {
    sampleDeleteMutation.mutate({ sampleId: idNumber })
  }

  useEffect(() => {
    if (sampleDeleteMutation.isSuccess) {
      setDeleteConfirmationOpen(false)
      navigate('/atlas/my-data')
    }
  }, [sampleDeleteMutation.isSuccess])

  if (sampleLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (sampleLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={
          sampleLoader.error?.response?.data?.detail?.toString() ||
          'Attribute not found'
        }
      />
    )
  }

  const sample = sampleLoader.data?.data

  if (!sample) {
    return (
      <ErrorCard
        title='Not Found'
        message={`Could not find sample with id: ${idNumber}`}
      />
    )
  }

  const editButtons = () => {
    return (
      <>
        <Link
          to={`/atlas/samples/${idNumber}/edit`}
          style={{ color: 'inherit' }}>
          <Tooltip title='Edit sample'>
            <IconButton size='small'>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <IconButton
          size='small'
          onClick={() => setDeleteConfirmationOpen(true)}>
          <Tooltip title='Delete sample'>
            <DeleteIcon sx={{ color: 'error.main' }} />
          </Tooltip>
        </IconButton>
        <Dialog
          open={deleteConfirmationOpen}
          onClose={() => setDeleteConfirmationOpen(false)}>
          <DialogTitle>Confirm Deletion of Sample</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete the sample
              <br />
              <span style={{ fontWeight: 700 }}>
                {sampleLoader.data?.data.name}
              </span>
              ?
              <br />
              This action cannot be undone.
              <br />
              (Datasets, including those of other users, that reference this
              sample will be affected)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setDeleteConfirmationOpen(false)}
              color='secondary'
              disabled={sampleDeleteMutation.isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              color='error'
              disabled={sampleDeleteMutation.isLoading}>
              Confirm Deletion
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <Grid container columns={{ xs: 6, md: 12 }} spacing={2}>
      <Grid xs={12}>
        <Paper sx={{ p: 4 }}>
          <Stack direction={'row'}>
            <Box flexGrow={1}>
              <Typography
                variant='headlineMedium'
                title={sample?.name}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {sample?.grain} Sample / {sample?.name}
              </Typography>
              <Typography variant='captionSmall' color='text.secondary'>
                Created by {sample?.author.full_name} on{' '}
                {dateStringDisplayFormat(sample?.created_at || '')}
              </Typography>
            </Box>
            <Box>{isUserOwner?.data?.data === true && editButtons()}</Box>
          </Stack>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <Link
              to={{
                pathname: '/tools/plotter',
                search: `?tab=${1}&sampleIds=${sample.id}`,
              }}>
              <Button size='small'>Plot Sample</Button>
            </Link>
          </Stack>
        </Paper>
      </Grid>

      <Grid xs={12} xl={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Description</Typography>
          </Divider>
          <Typography variant='bodyMedium'>{sample?.description}</Typography>
        </Paper>
      </Grid>
      <Grid xs={12} xl={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Filters</Typography>
          </Divider>
          <Box>
            {sample.filters?.map(filter => (
              <FilterChip key={filter.id} filter={filter} />
            ))}
          </Box>
        </Paper>
      </Grid>

      <Grid xs={12} xl={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Data Insights</Typography>
          </Divider>
          <Typography variant='bodyMedium'>...</Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
