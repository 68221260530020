import { Paper, Typography } from '@northvolt/ui'
import NewAttributeCollection from 'components/AttributeCollection/NewAttributeCollection'

export default function Page() {
  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>
        Create New Attribute Collection
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        An <i>attribute collection</i> is a set of attributes that can be
        interesting to analyze together. A <i>Dataset</i> in Verona consists of
        at least one attribute collection, and at least one <i>Sample</i>.
      </Typography>
      <NewAttributeCollection openOnCreate={true} />
    </Paper>
  )
}
