import type React from 'react'
import { useState } from 'react'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BookIcon from '@mui/icons-material/Book'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import HomeIcon from '@mui/icons-material/Home'
import InsightsIcon from '@mui/icons-material/Insights'
import SearchIcon from '@mui/icons-material/Search'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppMenu, Box, type TAppMenuListItem } from '@northvolt/ui'
import { NVLocalizationProviderDayjs } from '@northvolt/ui/ProviderDayjs'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { SnowflakeProvider, configureSnowflake } from '@northvolt/snowflake'
import { NVThemeProvider } from '@northvolt/ui'
import Loading from 'components/Loading'
import LoginForm from 'components/LoginForm'
import { ApiProvider } from 'contexts/APIContext'
import { FormProvider } from 'contexts/FormContext'
import QueryProvider from 'contexts/QueryContext'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import VeronaTopBar from './VeronaTopBar'

interface VeronaAppMenuProps {
  children: React.ReactNode
}

const VeronaAppMenu: React.FC<VeronaAppMenuProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const menuListItems: (TAppMenuListItem & { startPath?: string })[] = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      onClick: () => navigate('/'),
      active: !!matchPath(pathname, '/'),
    },
    { label: 'Data Atlas', type: 'heading' },
    {
      label: 'Search',
      icon: <SearchIcon />,
      onClick: () => navigate('/atlas/search'),
      active: !!matchPath(pathname, '/atlas/search'),
    },
    {
      label: 'My Data',
      icon: <BookIcon />,
      onClick: () => navigate('/atlas/my-data'),
      active: !!matchPath(pathname, '/atlas/my-data'),
    },
    { label: 'Data Exploration Tools', type: 'heading' },
    {
      label: 'Plotter',
      icon: <InsightsIcon />,
      onClick: () => navigate('/plotter'),
      active: !!matchPath(pathname, '/plotter'),
    },
    {
      label: 'Change Detector',
      icon: <TrackChangesIcon />,
      onClick: () => navigate('/change-detector'),
      active: !!matchPath(pathname, '/change-detector'),
    },
    {
      label: 'Correlation Finder',
      icon: <TroubleshootIcon />,
      onClick: () => navigate('/correlation-finder'),
      active: !!matchPath(pathname, '/correlation-finder'),
    },
    {
      label: 'Turbo Fishbones',
      icon: <AccountTreeIcon />,
      onClick: () => navigate('/turbo-fishbones'),
      active: !!matchPath(pathname, '/turbo-fishbones'),
    },
    { label: 'Statistical Process Control', type: 'heading' },
    {
      label: 'Process Capability',
      icon: <EqualizerIcon />,
      onClick: () => navigate('/spc/process-capability'),
      active: !!matchPath(pathname, '/spc/process-capability'),
    },
  ]

  useEffect(() => {
    configureSnowflake({
      AUTH_SCOPE: 'rome',
      AUTH_LOGIN_URL: import.meta.env.VITE_AUTH_LOGIN_URL,
      COGNITO_CLIENT_ID: import.meta.env.VITE_COGNITO_CLIENT_ID,
      COGNITO_USER_POOL_URL: import.meta.env.VITE_COGNITO_USER_POOL_URL,
      COGNITO_IDENTITY_PROVIDER: 'Northvolt',
    })

    mixpanel.init('a6690870c437905dea8db4ac58688673', {
      api_host: 'https://api-eu.mixpanel.com',
      persistence: 'localStorage',
      track_pageview: true,
      debug: false,
    })
  }, [])

  return (
    <NVThemeProvider>
      <SnowflakeProvider
        loadingState={<Loading />}
        signedOutState={<LoginForm />}>
        <QueryProvider>
          <NVLocalizationProviderDayjs dateAdapter={AdapterDayjs}>
            <Box display='flex' flexDirection={'column'} height={'100vh'}>
              <AppMenu
                open={open}
                onOpenChange={setOpen}
                menuListItems={menuListItems}
                offsetAppBar={false}>
                <VeronaTopBar menuOpen={open} setMenuOpen={setOpen} />
                <Box
                  sx={{
                    pt: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}>
                  <ApiProvider>
                    <FormProvider>{children}</FormProvider>
                  </ApiProvider>
                </Box>
              </AppMenu>
            </Box>
          </NVLocalizationProviderDayjs>
        </QueryProvider>
      </SnowflakeProvider>
    </NVThemeProvider>
  )
}

export default VeronaAppMenu
