import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import { Chip } from '@northvolt/ui'
import type { AttributeLight } from 'client/model'
import { Link } from 'react-router-dom'

interface AttributeChipProps {
  attribute: AttributeLight
  chipProps?: any
  clickable?: boolean
}

const AttributeChip: React.FC<AttributeChipProps> = ({
  attribute,
  chipProps,
  clickable,
}) => {
  const { key, ...props } = chipProps ?? {}
  const chip = (
    <Chip
      label={attribute?.unique_name}
      title={`Attribute: ${attribute?.unique_name}`}
      size='small'
      sx={{ m: 0.25 }}
      color='secondary'
      variant='outlined'
      icon={<ViewColumnIcon />}
      {...props}
    />
  )
  if (clickable) {
    return (
      <Link
        to={`/atlas/attributes/${attribute.id}`}
        style={{ color: 'inherit' }}>
        {chip}
      </Link>
    )
  } else {
    return chip
  }
}

export default AttributeChip
