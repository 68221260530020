import { Box, useTheme } from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import type { SelectableAttribute } from 'components/DataLoader/DataLoaderTypes'
import { AttributeType, getAttributeType } from 'components/Utils'
import { useEffect, useRef, useState } from 'react'
import { colorScheme, colorSchemeCat } from './PlotUtils'

interface Plot1DProps {
  attribute: SelectableAttribute
  width: number
  height: number
  isColorAttr?: boolean
  isSizeAttr?: boolean
  $filterParam: any
  renderId?: number
}

const Plot1D: React.FC<Plot1DProps> = ({
  attribute,
  width,
  height,
  isColorAttr,
  $filterParam,
  renderId,
}) => {
  const [plot, setPlot] = useState<any>(null)

  const theme = useTheme()
  const themeGrey = theme.palette.grey[700]

  const plotRef = useRef<any>(null)

  useEffect(() => {
    if (attribute && width && height < Number.POSITIVE_INFINITY) {
      const updatePlot = async () => {
        const attrType = getAttributeType(attribute)
        if (attrType === AttributeType.numeric) {
          const p = await getHistogramPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else if (attrType === AttributeType.datetime) {
          const p = await getHistogramPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else if (attrType === AttributeType.categorical) {
          const p = getCategoricalPlot(
            attribute,
            $filterParam,
            width,
            height,
            isColorAttr || false,
            themeGrey,
          )
          setPlot(p)
        } else {
          console.log('Unknown attribute type')
        }
      }
      updatePlot()
    } else {
      setPlot(vg.vspace(0))
    }
  }, [attribute, width, height, isColorAttr, themeGrey, renderId])

  useEffect(() => {
    if (plotRef.current && plot) {
      plotRef.current.appendChild(plot)
      return () => {
        if (plotRef.current) {
          plotRef.current.removeChild(plot)
        }
      }
    }
  }, [plot, plotRef])

  return <Box ref={plotRef} />
}

export default Plot1D

function getHistogramPlot(
  attribute: SelectableAttribute,
  $filterParam: any,
  width: number,
  height: number,
  isColorAttr: boolean,
  themeGrey: string,
) {
  const fillColor = isColorAttr
    ? vg.median(vg.column(attribute?.unique_name))
    : themeGrey

  return vg.plot(
    vg.rectY(
      // Background greyed out histogram
      vg.from('active_table'),
      {
        x: vg.bin(attribute?.unique_name),
        y: vg.count(),
        z: null,
        stroke: 'black',
        fill: fillColor,
        inset: 0.5,
        opacity: 0.2,
      },
    ),
    vg.rectY(vg.from('active_table', { filterBy: $filterParam }), {
      x: vg.bin(attribute?.unique_name),
      y: vg.count(),
      stroke: 'black',
      fill: fillColor,
      inset: 0.5,
      tip: true,
    }),
    vg.intervalX({ as: $filterParam }),
    vg.colorDomain(vg.Fixed),
    vg.width(width),
    vg.height(height),
    vg.marginBottom(40),
    vg.colorScheme(colorScheme),
    vg.colorScale('log'),
    vg.xLabel(`${attribute.column_name} →`),
    vg.colorLabel(isColorAttr ? attribute.column_name : null),
  )
}

function getCategoricalPlot(
  attribute: SelectableAttribute,
  $filterParam: any,
  width: number,
  height: number,
  isColorAttr: boolean,
  themeGrey: string,
) {
  const fillColor = isColorAttr
    ? vg.median(vg.column(attribute?.unique_name))
    : themeGrey
  return vg.plot(
    vg.rectY(
      // Background greyed out histogram
      vg.from('active_table'),
      {
        x: attribute?.unique_name,
        y: vg.count(),
        stroke: 'black',
        fill: fillColor,
        inset: 0.5,
        opacity: 0.2,
      },
    ),
    vg.rectY(vg.from('active_table', { filterBy: $filterParam }), {
      x: attribute?.unique_name,
      y: vg.count(),
      stroke: 'black',
      fill: fillColor,
      inset: 0.5,
      tip: true,
    }),
    vg.marginBottom(40),
    vg.toggleX({ as: $filterParam }),
    vg.width(width),
    vg.height(height),
    vg.colorScheme(colorSchemeCat),
    vg.colorDomain(vg.Fixed),
    vg.xLabel(`${attribute.column_name} →`),
    vg.xTickRotate(45),
    vg.colorLabel(isColorAttr ? attribute?.column_name : null),
  )
}
