import { FormControl } from '@mui/material'
import { Box, Stack, Switch, Tooltip } from '@northvolt/ui'
import type { AttributeLight } from 'client/model'
import { DataType } from 'components/Utils'
import React, { useEffect } from 'react'
import AttributeSelector from '../Attribute/AttributeSelector'
import type { SelectableAttributeCollection } from './DataLoaderTypes'

interface StratificationControlProps {
  attributeCollections: SelectableAttributeCollection[]
  stratificationAttributeName: string | undefined
  setStratificationAttributeName: (attributeName: string | undefined) => void
}

const StratificationControl: React.FC<StratificationControlProps> = ({
  attributeCollections,
  stratificationAttributeName,
  setStratificationAttributeName,
}) => {
  const [stratify, setStratify] = React.useState(!!stratificationAttributeName)
  const [allOptions, setAllOptions] = React.useState<
    SelectableAttributeCollection[]
  >([])
  const [selectedOption, setSelectedOption] = React.useState<
    AttributeLight | undefined
  >(undefined)

  const defaultStratifcationAttribute: AttributeLight = {
    column_name: 'sample_name',
    data_type: DataType.STRING,
    id: -1,
    grain: 'Prismatic Cell',
    unique_name: 'sample_name',
  }

  useEffect(() => {
    setAllOptions([
      {
        name: 'Default',
        attributes: [defaultStratifcationAttribute],
        grain: 'Prismatic Cell',
      },
      ...attributeCollections,
    ])
  }, [attributeCollections])

  useEffect(() => {
    if (stratificationAttributeName) {
      const attribute = allOptions
        .flatMap(collection => collection.attributes)
        .find(
          attribute => attribute?.unique_name === stratificationAttributeName,
        )
      setSelectedOption(attribute)
    } else {
      setSelectedOption(undefined)
    }
  }, [stratificationAttributeName, allOptions])

  useEffect(
    () =>
      setStratificationAttributeName(
        stratify ? defaultStratifcationAttribute?.unique_name : undefined,
      ),
    [stratify],
  )

  return (
    <>
      <Stack spacing={2} direction={'row'}>
        {true && (
          <Box sx={{ width: '100%' }}>
            <AttributeSelector
              attributeCollections={allOptions}
              selectedAttributes={selectedOption ? [selectedOption] : []}
              setSelectedAttributes={attributes =>
                setStratificationAttributeName(attributes[0]?.unique_name)
              }
              disabled={!stratify}
              label='Stratification attribute'
              multiple={false}
              dataTypeWhitelist={[DataType.STRING, DataType.BOOLEAN]}
              tooltip={
                stratify
                  ? 'Select the attribute to stratify the data by.'
                  : 'Enable stratification first.'
              }
            />
          </Box>
        )}
        <Box>
          <Tooltip
            placement='top-start'
            title={
              'Enable this to group the data by an attribute, and attempt fetch an equal number from each group.'
            }>
            <FormControl fullWidth>
              <Switch
                label='Stratify'
                checked={stratify}
                onChange={() => setStratify(!stratify)}
              />
            </FormControl>
          </Tooltip>
        </Box>
      </Stack>
    </>
  )
}

export default StratificationControl
