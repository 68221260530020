import { Link, Paper, Typography } from '@northvolt/ui'

export default function Route() {
  return (
    <Paper elevation={1} sx={{ p: 4 }}>
      <Typography variant='headlineLarge'>Need Some Help?</Typography>

      <Typography variant='headlineMedium' sx={{ pt: 4 }}>
        Getting Started
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 2 }}>
        Before using Verona, it is recommend that you check out our{' '}
        <Link
          href={
            'https://northvolt0.sharepoint.com/:p:/s/MachineintelligenceSoftware/EZo2tRaEPOZEnDRiClTXvzMBY-7a6KRKWm3udrL0lFYv2g'
          }
          style={{ color: 'inherit' }}
          target='_blank'>
          Onboarding Guide
        </Link>
        .
      </Typography>

      <Typography variant='headlineMedium' sx={{ pt: 4 }}>
        Getting Help
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 2 }}>
        Verona is in active development and you may run into issues. If so,
        please post in our{' '}
        <Link
          href={
            'https://teams.microsoft.com/l/channel/19%3Ade1647b221a6494f8b8edce60eac8c3b%40thread.tacv2/Verona?groupId=4093c19b-98c0-456c-9f2c-6945505929b4&tenantId=706c5db9-5278-483b-b622-70084f823a12'
          }
          style={{ color: 'inherit' }}
          target='_blank'>
          Teams Channel
        </Link>{' '}
        and we will get back to you as soon as possible.
      </Typography>
    </Paper>
  )
}
