import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Box, IconButton } from '@mui/material'
import { Button, Paper, Popover, Stack, Typography } from '@northvolt/ui'
import { useAddAttributeToCollection } from 'client/atlas/atlas'
import type { Attribute, AttributeCollectionLight } from 'client/model'
import { useEffect, useState } from 'react'
import AttributeCollectionSearchAndSelect from './AttributeCollectionSearchAndSelect'

interface AddToCollectionPopoverProps {
  attribute: Attribute
  iconButton?: boolean
}

const AddToCollectionPopover: React.FC<AddToCollectionPopoverProps> = ({
  attribute,
  iconButton,
}) => {
  const attributeCollectionAdder = useAddAttributeToCollection()

  useEffect(() => {
    if (attributeCollectionAdder.isSuccess) {
      handleClose()
    }
  }, [attributeCollectionAdder.isSuccess])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const [selectedAttributeCollection, setSelectedAttributeCollection] =
    //@ts-ignore - useState expects a type
    useState<AttributeCollectionLight>(undefined)

  const addAttributeToCollection = () => {
    const oldAttributes = selectedAttributeCollection.attributes
    if (oldAttributes?.find(a => a.id === attribute.id)) {
      return
    }
    oldAttributes?.push(attribute)
    attributeCollectionAdder.mutate({
      attributeCollectionId: selectedAttributeCollection.id,
      params: { attribute_id: attribute.id },
    })
  }
  return (
    <Box>
      {iconButton ? (
        <IconButton size='small' onClick={handleClick}>
          <PlaylistAddIcon />
        </IconButton>
      ) : (
        <Button size='small' onClick={handleClick} sx={{ minWidth: 128 }}>
          Add to Collection
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ zIndex: 1200, mt: 0.5 }}>
        <Paper sx={{ p: 2 }} elevation={16}>
          <Stack direction='column' spacing={2} sx={{ width: 400 }}>
            <Typography variant='headlineSmall'>Add to Collection</Typography>
            <AttributeCollectionSearchAndSelect
              label='Select Collection'
              selectedAttributeCollections={
                selectedAttributeCollection ? [selectedAttributeCollection] : []
              }
              setSelectedAttributeCollections={collections =>
                setSelectedAttributeCollection(collections[0])
              }
              multiple={false}
              onlyUserCollections={true}
              grain={attribute.grain}
            />
            <Button
              onClick={addAttributeToCollection}
              size='small'
              sx={{ width: 'fit-content' }}
              disabled={!selectedAttributeCollection}>
              Add
            </Button>
          </Stack>
        </Paper>
      </Popover>
    </Box>
  )
}

export default AddToCollectionPopover
