import { Paper, Typography } from '@northvolt/ui'
import {
  useIsAttributeCollectionOwnedByUser,
  useReadAttributeCollection,
} from 'client/atlas/atlas'
import EditAttributeCollection from 'components/AttributeCollection/EditAttributeCollection'
import { useParams } from 'react-router-dom'

export default function Page() {
  const { attributeId } = useParams()
  const idNumber = Number.parseInt(attributeId || '', 10) || 0

  const isUserOwner = useIsAttributeCollectionOwnedByUser(idNumber)
  const attrCollLoader = useReadAttributeCollection(idNumber)

  if (attrCollLoader.isLoading) {
    return <div>Loading attribute collection: {idNumber}...</div>
  }

  if (!attrCollLoader.data) {
    return <div>Error loading attribute collection: {idNumber}</div>
  }

  const editAttributeCollection = () => {
    return (
      <>
        <EditAttributeCollection
          currentAttributeCollection={attrCollLoader?.data?.data}
        />
      </>
    )
  }

  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>
        Edit Attribute Collection: ID: {idNumber} -{' '}
        {attrCollLoader.data.data.name}
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        An <i>attribute collection</i> in Verona consists of a set of
        attributes, i.e., measurements/features/properties of an item (e.g. a
        cell). Together with <i>sample(s)</i>, <i>attribute collection(s)</i>{' '}
        form a <i>dataset</i>.
      </Typography>
      {isUserOwner?.data?.data === true ? (
        editAttributeCollection()
      ) : (
        <Typography variant='bodyMedium' color='error'>
          You do not have permission to edit this attribute collection owned by{' '}
          {attrCollLoader.data.data.author.full_name}.
        </Typography>
      )}
    </Paper>
  )
}
