import { Paper, Typography } from '@northvolt/ui'
import NewDataset from 'components/Dataset/NewDataset'

export default function Page() {
  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>Create New Dataset</Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        A <i>dataset</i> in Verona consists of at least one <i>sample</i> and at
        least one <i>attribute collection</i>. A <i>sample</i> is a set of
        filter conditions that together specify a sub-population of items (e.g.
        cells). An <i>attribute collection</i> is a set of attributes, i.e.,
        measurements/features/properties of an item.
      </Typography>
      <NewDataset />
    </Paper>
  )
}
