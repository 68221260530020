import DatasetIcon from '@mui/icons-material/Dataset'
import { Chip } from '@northvolt/ui'
import type { Dataset } from 'client/model'

interface DatasetChipProps {
  dataset: Dataset
  chipProps?: any
}

const DatasetChip: React.FC<DatasetChipProps> = ({ dataset, chipProps }) => {
  const { key, ...props } = chipProps ?? {}
  return (
    <Chip
      label={dataset?.name}
      title={`Dataset: ${dataset?.name}`}
      size='small'
      sx={{ m: 0.25 }}
      color='secondary'
      variant='outlined'
      icon={<DatasetIcon />}
      {...props}
    />
  )
}

export default DatasetChip
