import ListIcon from '@mui/icons-material/List'
import { Chip, Link } from '@northvolt/ui'
import type { AttributeCollectionLight } from 'client/model'
import type React from 'react'

interface AttributeCollectionChipProps {
  attributeCollection: AttributeCollectionLight
  chipProps?: any
  clickable?: boolean
}

const AttributeCollectionChip: React.FC<AttributeCollectionChipProps> = ({
  attributeCollection,
  chipProps,
  clickable,
}) => {
  const { key, ...props } = chipProps ?? {}
  const chip = (
    <Chip
      label={attributeCollection?.name}
      title={`AttributeCollection: ${attributeCollection?.name}`}
      size='small'
      sx={{ m: 0.25 }}
      color='secondary'
      variant='outlined'
      icon={<ListIcon />}
      {...props}
    />
  )

  if (clickable) {
    return (
      <Link
        href={`/atlas/attribute-collections/${attributeCollection.id}`}
        style={{ color: 'inherit' }}>
        {chip}
      </Link>
    )
  } else {
    return chip
  }
}

export default AttributeCollectionChip
