import { Paper, Typography } from '@northvolt/ui'
import { useIsSampleOwnedByUser, useReadSample } from 'client/atlas/atlas'
import EditSample from 'components/Sample/EditSample'
import { useParams } from 'react-router-dom'

export default function Page() {
  const { sampleId } = useParams()
  const idNumber = Number.parseInt(sampleId || '', 10) || 0

  const isUserOwner = useIsSampleOwnedByUser(idNumber)
  const sampleLoader = useReadSample(idNumber)

  if (sampleLoader.isLoading) {
    return <div>Loading sample: {idNumber}...</div>
  }

  if (!sampleLoader.data) {
    return <div>Error loading sample: {idNumber}</div>
  }

  const editSample = () => {
    return (
      <>
        <EditSample currentSample={sampleLoader?.data?.data} />
      </>
    )
  }

  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>
        Edit Sample: ID: {idNumber} - {sampleLoader.data.data.name}
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        A <i>sample</i> in Verona consists of a set of filter conditions that
        together specify a sub-population of items (e.g. cells). Together with{' '}
        <i>attribute collection(s)</i>, <i>sample(s)</i> form a <i>dataset</i>.
      </Typography>
      {isUserOwner?.data?.data === true ? (
        editSample()
      ) : (
        <Typography variant='bodyMedium' color='error'>
          You do not have permission to edit this sample owned by{' '}
          {sampleLoader.data.data.author.full_name}.
        </Typography>
      )}
    </Paper>
  )
}
