import { Paper, Typography } from '@northvolt/ui'
import { useIsDatasetOwnedByUser, useReadDataset } from 'client/atlas/atlas'
import EditDataset from 'components/Dataset/EditDataset'
import { useParams } from 'react-router-dom'

export default function Page() {
  const { datasetId } = useParams()
  const idNumber = Number.parseInt(datasetId || '', 10) || 0

  const isUserOwner = useIsDatasetOwnedByUser(idNumber)
  const datasetLoader = useReadDataset(idNumber)

  if (datasetLoader.isLoading) {
    return <div>Loading dataset: {idNumber}...</div>
  }

  if (!datasetLoader.data) {
    return <div>Error loading dataset: {idNumber}</div>
  }

  const editDataset = () => {
    return (
      <>
        <EditDataset currentDataset={datasetLoader?.data?.data} />
      </>
    )
  }

  return (
    <Paper elevation={1} sx={{ p: 4, height: '100%', overflow: 'auto' }}>
      <Typography variant='headlineMedium'>
        Edit Dataset: ID: {idNumber} - {datasetLoader.data.data.name}
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 1, pb: 4 }}>
        A <i>dataset</i> in Verona consists of at least one <i>sample</i> and at
        least one <i>attribute collection</i>. A <i>sample</i> is a set of
        filter conditions that together specify a sub-population of items (e.g.
        cells). An <i>attribute collection</i> is a set of attributes, i.e.,
        measurements/features/properties of an item.
      </Typography>
      {isUserOwner?.data?.data === true ? (
        editDataset()
      ) : (
        <Typography variant='bodyMedium' color='error'>
          You do not have permission to edit this dataset owned by{' '}
          {datasetLoader.data.data.author.full_name}.
        </Typography>
      )}
    </Paper>
  )
}
