import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { IconButton, ListItem, Stack, Typography } from '@northvolt/ui'
import type { AttributeCollectionLight } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import { getHighlightedText } from './../Utils'

interface AttributeCollectionSelectItemProps {
  attributeCollection: AttributeCollectionLight
  listItemProps?: any
  searchText?: string
}

const AttributeCollectionSelectItem: React.FC<
  AttributeCollectionSelectItemProps
> = ({ attributeCollection, listItemProps, searchText }) => {
  return (
    <ListItem divider sx={{ px: 1, py: 1 }} {...listItemProps}>
      <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
        <Typography title={attributeCollection.name} variant='subtitleMedium'>
          {getHighlightedText(attributeCollection.name, searchText)}
        </Typography>
        <Typography variant='captionSmall' color='text.secondary'>
          {getHighlightedText(
            attributeCollection.description?.toString(),
            searchText,
          )}
        </Typography>
      </Stack>
      <IconButton edge='end' size='small'>
        <Link
          to={`/atlas/attribute-collections/${attributeCollection.id}`}
          style={{ color: 'inherit' }}
          target='_blank'>
          <ReadMoreIcon />
        </Link>
      </IconButton>
    </ListItem>
  )
}

export default AttributeCollectionSelectItem
