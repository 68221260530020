import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import InsightsIcon from '@mui/icons-material/Insights'
import SearchIcon from '@mui/icons-material/Search'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { Divider, Grid, Paper, Typography } from '@northvolt/ui'
import MenuCard from 'components/MenuCard'

export default function Route() {
  return (
    <Grid
      columns={{ xs: 1, sm: 2, lg: 3, xl: 4 }}
      container
      spacing={{ xs: 2 }}
      sx={{ pb: 8 }}>
      <Grid xs={4}>
        <Paper elevation={1} sx={{ p: 4 }}>
          <Typography variant='headlineLarge'>Welcome to Verona</Typography>
          <Typography variant='subtitleMedium'>
            Your Battery Data Co-Pilot
          </Typography>
          <Typography variant='bodyMedium' sx={{ pt: 2 }}>
            This application is in early development. Many things are not
            working as intended. Everything is subject to change.
          </Typography>
          <Typography variant='bodyMedium'>
            If you are new to Verona, click on the help icon in the top right
            corner of the page.
          </Typography>
        </Paper>
      </Grid>
      <Grid xs={4}>
        <Divider>
          <Typography variant='headlineMedium'>Data Atlas</Typography>
        </Divider>
      </Grid>
      <Grid xs={1}>
        <MenuCard
          delay={0}
          title='Search'
          category='Atlas'
          description='Search and read up on available attributes.'
          link='/atlas/search'
          icon={<SearchIcon />}
        />
      </Grid>
      <Grid xs={1}>
        <MenuCard
          delay={0.2}
          title='My Data'
          category='Atlas'
          description='Create and manage your own datasets and favorites.'
          link='atlas/my-data'
          icon={<BookOutlinedIcon />}
        />
      </Grid>
      <Grid xs={4}>
        <Divider>
          <Typography variant='headlineMedium'>
            Data Exploration Tools
          </Typography>
        </Divider>
      </Grid>
      <Grid xs={1}>
        <MenuCard
          delay={0.3}
          title='Plotter'
          category='Visualize'
          description='Plot and explore data.'
          link='tools/plotter'
          icon={<InsightsIcon />}
        />
      </Grid>
      <Grid xs={1}>
        <MenuCard
          delay={0.4}
          title='Change Detector'
          category='Time Series Analysis'
          description='Detect process changes.'
          link='tools/change-detector'
          icon={<TrackChangesIcon />}
        />
      </Grid>
      <Grid xs={4}>
        <Divider>
          <Typography variant='headlineMedium'>
            Statistical Process Control
          </Typography>
        </Divider>
      </Grid>
      <Grid xs={1}>
        <MenuCard
          delay={0.5}
          title='Process Capability'
          category='SPC'
          description='Make sure your process is in control.'
          link='spc/process-capability'
          icon={<EqualizerIcon />}
        />
      </Grid>
    </Grid>
  )
}
