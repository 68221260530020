/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AddAttributeToCollectionParams,
  Attribute,
  AttributeCollection,
  AttributeCollectionCreate,
  AttributeCollectionLight,
  AttributeCreate,
  AttributeVersion,
  Dataset,
  DatasetCreate,
  DatasetLight,
  GetProcessEndedAtAttributeParams,
  HTTPValidationError,
  PaginatedResponseAttribute,
  PaginatedResponseAttributeCollection,
  PaginatedResponseAttributeCollectionLight,
  PaginatedResponseDatasetLight,
  PaginatedResponseSample,
  PaginatedResponseSampleLight,
  PaginatedResponseSearchTag,
  ReadAttributeCollectionsByAttributeParams,
  ReadAttributeCollectionsByIdsParams,
  ReadAttributeCollectionsByUserParams,
  ReadAttributeCollectionsParams,
  ReadAttributesParams,
  ReadDatasetsByUserParams,
  ReadDatasetsParams,
  ReadFavoriteDatasetsParams,
  ReadSamplesByIdsParams,
  ReadSamplesByUserParams,
  ReadSamplesParams,
  ReadSearchTagsParams,
  Sample,
  SampleCreate,
  SampleLight,
  SearchAttributeCollectionsByTagsParams,
  SearchAttributeCollectionsByTextParams,
  SearchAttributesByTagsParams,
  SearchAttributesByTextParams,
  SearchDatasetsByTagsParams,
  SearchDatasetsByTextParams,
  SearchRemainingSearchTagsParams,
  SearchSamplesByTagsParams,
  SearchSamplesByTextParams,
  SearchTag
} from '.././model'



/**
 * @summary Create Attribute
 */
export const createAttribute = (
    attributeCreate: AttributeCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attribute>> => {
    
    return axios.default.post(
      `/api/atlas/attributes`,
      attributeCreate,options
    );
  }



export const getCreateAttributeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAttribute>>, TError,{data: AttributeCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createAttribute>>, TError,{data: AttributeCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAttribute>>, {data: AttributeCreate}> = (props) => {
          const {data} = props ?? {};

          return  createAttribute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof createAttribute>>>
    export type CreateAttributeMutationBody = AttributeCreate
    export type CreateAttributeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Attribute
 */
export const useCreateAttribute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAttribute>>, TError,{data: AttributeCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createAttribute>>,
        TError,
        {data: AttributeCreate},
        TContext
      > => {

      const mutationOptions = getCreateAttributeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attributes
 */
export const readAttributes = (
    params?: ReadAttributesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttribute>> => {
    
    return axios.default.get(
      `/api/atlas/attributes`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadAttributesQueryKey = (params?: ReadAttributesParams,) => {
    return [`/api/atlas/attributes`, ...(params ? [params]: [])] as const;
    }

    
export const getReadAttributesQueryOptions = <TData = Awaited<ReturnType<typeof readAttributes>>, TError = AxiosError<HTTPValidationError>>(params?: ReadAttributesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributes>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributes>>> = ({ signal }) => readAttributes(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributes>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributesQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributes>>>
export type ReadAttributesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attributes
 */
export const useReadAttributes = <TData = Awaited<ReturnType<typeof readAttributes>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadAttributesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributes>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Attribute
 */
export const readAttribute = (
    attributeId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attribute>> => {
    
    return axios.default.get(
      `/api/atlas/attributes/${attributeId}`,options
    );
  }


export const getReadAttributeQueryKey = (attributeId: number,) => {
    return [`/api/atlas/attributes/${attributeId}`] as const;
    }

    
export const getReadAttributeQueryOptions = <TData = Awaited<ReturnType<typeof readAttribute>>, TError = AxiosError<HTTPValidationError>>(attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttribute>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeQueryKey(attributeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttribute>>> = ({ signal }) => readAttribute(attributeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttribute>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeQueryResult = NonNullable<Awaited<ReturnType<typeof readAttribute>>>
export type ReadAttributeQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute
 */
export const useReadAttribute = <TData = Awaited<ReturnType<typeof readAttribute>>, TError = AxiosError<HTTPValidationError>>(
 attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttribute>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeQueryOptions(attributeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Search Attributes By Text
 */
export const searchAttributesByText = (
    params: SearchAttributesByTextParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttribute>> => {
    
    return axios.default.post(
      `/api/atlas/attributes/text_search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchAttributesByTextMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByText>>, TError,{params: SearchAttributesByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByText>>, TError,{params: SearchAttributesByTextParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchAttributesByText>>, {params: SearchAttributesByTextParams}> = (props) => {
          const {params} = props ?? {};

          return  searchAttributesByText(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchAttributesByTextMutationResult = NonNullable<Awaited<ReturnType<typeof searchAttributesByText>>>
    
    export type SearchAttributesByTextMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Attributes By Text
 */
export const useSearchAttributesByText = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByText>>, TError,{params: SearchAttributesByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchAttributesByText>>,
        TError,
        {params: SearchAttributesByTextParams},
        TContext
      > => {

      const mutationOptions = getSearchAttributesByTextMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Attributes By Tags
 */
export const searchAttributesByTags = (
    searchAttributesByTagsBody: number[],
    params?: SearchAttributesByTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttribute>> => {
    
    return axios.default.post(
      `/api/atlas/attributes/tag_search`,
      searchAttributesByTagsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchAttributesByTagsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByTags>>, TError,{data: number[];params?: SearchAttributesByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByTags>>, TError,{data: number[];params?: SearchAttributesByTagsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchAttributesByTags>>, {data: number[];params?: SearchAttributesByTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  searchAttributesByTags(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchAttributesByTagsMutationResult = NonNullable<Awaited<ReturnType<typeof searchAttributesByTags>>>
    export type SearchAttributesByTagsMutationBody = number[]
    export type SearchAttributesByTagsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Attributes By Tags
 */
export const useSearchAttributesByTags = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributesByTags>>, TError,{data: number[];params?: SearchAttributesByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchAttributesByTags>>,
        TError,
        {data: number[];params?: SearchAttributesByTagsParams},
        TContext
      > => {

      const mutationOptions = getSearchAttributesByTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get Process Ended At Attribute
 */
export const getProcessEndedAtAttribute = (
    params: GetProcessEndedAtAttributeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attribute>> => {
    
    return axios.default.post(
      `/api/atlas/attributes/process_ended_at`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGetProcessEndedAtAttributeMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProcessEndedAtAttribute>>, TError,{params: GetProcessEndedAtAttributeParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof getProcessEndedAtAttribute>>, TError,{params: GetProcessEndedAtAttributeParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getProcessEndedAtAttribute>>, {params: GetProcessEndedAtAttributeParams}> = (props) => {
          const {params} = props ?? {};

          return  getProcessEndedAtAttribute(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetProcessEndedAtAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof getProcessEndedAtAttribute>>>
    
    export type GetProcessEndedAtAttributeMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Get Process Ended At Attribute
 */
export const useGetProcessEndedAtAttribute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getProcessEndedAtAttribute>>, TError,{params: GetProcessEndedAtAttributeParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof getProcessEndedAtAttribute>>,
        TError,
        {params: GetProcessEndedAtAttributeParams},
        TContext
      > => {

      const mutationOptions = getGetProcessEndedAtAttributeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute History
 */
export const readAttributeHistory = (
    attributeId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeVersion[]>> => {
    
    return axios.default.get(
      `/api/atlas/attributes/${attributeId}/history`,options
    );
  }


export const getReadAttributeHistoryQueryKey = (attributeId: number,) => {
    return [`/api/atlas/attributes/${attributeId}/history`] as const;
    }

    
export const getReadAttributeHistoryQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeHistory>>, TError = AxiosError<HTTPValidationError>>(attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeHistory>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeHistoryQueryKey(attributeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeHistory>>> = ({ signal }) => readAttributeHistory(attributeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeHistory>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeHistory>>>
export type ReadAttributeHistoryQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute History
 */
export const useReadAttributeHistory = <TData = Awaited<ReturnType<typeof readAttributeHistory>>, TError = AxiosError<HTTPValidationError>>(
 attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeHistory>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeHistoryQueryOptions(attributeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Search Tags
 */
export const readSearchTags = (
    params?: ReadSearchTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSearchTag>> => {
    
    return axios.default.get(
      `/api/atlas/search_tags`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadSearchTagsQueryKey = (params?: ReadSearchTagsParams,) => {
    return [`/api/atlas/search_tags`, ...(params ? [params]: [])] as const;
    }

    
export const getReadSearchTagsQueryOptions = <TData = Awaited<ReturnType<typeof readSearchTags>>, TError = AxiosError<HTTPValidationError>>(params?: ReadSearchTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSearchTags>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadSearchTagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readSearchTags>>> = ({ signal }) => readSearchTags(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readSearchTags>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadSearchTagsQueryResult = NonNullable<Awaited<ReturnType<typeof readSearchTags>>>
export type ReadSearchTagsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Search Tags
 */
export const useReadSearchTags = <TData = Awaited<ReturnType<typeof readSearchTags>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadSearchTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSearchTags>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadSearchTagsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Search Remaining Search Tags
 */
export const searchRemainingSearchTags = (
    searchRemainingSearchTagsBody: number[],
    params?: SearchRemainingSearchTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSearchTag>> => {
    
    return axios.default.post(
      `/api/atlas/search_tags/search_remaining`,
      searchRemainingSearchTagsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchRemainingSearchTagsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRemainingSearchTags>>, TError,{data: number[];params?: SearchRemainingSearchTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchRemainingSearchTags>>, TError,{data: number[];params?: SearchRemainingSearchTagsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchRemainingSearchTags>>, {data: number[];params?: SearchRemainingSearchTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  searchRemainingSearchTags(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchRemainingSearchTagsMutationResult = NonNullable<Awaited<ReturnType<typeof searchRemainingSearchTags>>>
    export type SearchRemainingSearchTagsMutationBody = number[]
    export type SearchRemainingSearchTagsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Remaining Search Tags
 */
export const useSearchRemainingSearchTags = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRemainingSearchTags>>, TError,{data: number[];params?: SearchRemainingSearchTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchRemainingSearchTags>>,
        TError,
        {data: number[];params?: SearchRemainingSearchTagsParams},
        TContext
      > => {

      const mutationOptions = getSearchRemainingSearchTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Search Tag
 */
export const readSearchTag = (
    searchTagId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchTag>> => {
    
    return axios.default.get(
      `/api/atlas/search_tags/${searchTagId}`,options
    );
  }


export const getReadSearchTagQueryKey = (searchTagId: number,) => {
    return [`/api/atlas/search_tags/${searchTagId}`] as const;
    }

    
export const getReadSearchTagQueryOptions = <TData = Awaited<ReturnType<typeof readSearchTag>>, TError = AxiosError<HTTPValidationError>>(searchTagId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSearchTag>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadSearchTagQueryKey(searchTagId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readSearchTag>>> = ({ signal }) => readSearchTag(searchTagId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(searchTagId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readSearchTag>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadSearchTagQueryResult = NonNullable<Awaited<ReturnType<typeof readSearchTag>>>
export type ReadSearchTagQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Search Tag
 */
export const useReadSearchTag = <TData = Awaited<ReturnType<typeof readSearchTag>>, TError = AxiosError<HTTPValidationError>>(
 searchTagId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSearchTag>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadSearchTagQueryOptions(searchTagId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Attribute Collections
 */
export const readAttributeCollections = (
    params?: ReadAttributeCollectionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollectionLight>> => {
    
    return axios.default.get(
      `/api/atlas/attribute_collections`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadAttributeCollectionsQueryKey = (params?: ReadAttributeCollectionsParams,) => {
    return [`/api/atlas/attribute_collections`, ...(params ? [params]: [])] as const;
    }

    
export const getReadAttributeCollectionsQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeCollections>>, TError = AxiosError<HTTPValidationError>>(params?: ReadAttributeCollectionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollections>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeCollectionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeCollections>>> = ({ signal }) => readAttributeCollections(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollections>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeCollectionsQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeCollections>>>
export type ReadAttributeCollectionsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute Collections
 */
export const useReadAttributeCollections = <TData = Awaited<ReturnType<typeof readAttributeCollections>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadAttributeCollectionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollections>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeCollectionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create Attribute Collection
 */
export const createAttributeCollection = (
    attributeCollectionCreate: AttributeCollectionCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeCollection>> => {
    
    return axios.default.post(
      `/api/atlas/attribute_collections`,
      attributeCollectionCreate,options
    );
  }



export const getCreateAttributeCollectionMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAttributeCollection>>, TError,{data: AttributeCollectionCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createAttributeCollection>>, TError,{data: AttributeCollectionCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAttributeCollection>>, {data: AttributeCollectionCreate}> = (props) => {
          const {data} = props ?? {};

          return  createAttributeCollection(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAttributeCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof createAttributeCollection>>>
    export type CreateAttributeCollectionMutationBody = AttributeCollectionCreate
    export type CreateAttributeCollectionMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Attribute Collection
 */
export const useCreateAttributeCollection = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAttributeCollection>>, TError,{data: AttributeCollectionCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createAttributeCollection>>,
        TError,
        {data: AttributeCollectionCreate},
        TContext
      > => {

      const mutationOptions = getCreateAttributeCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Collection
 */
export const readAttributeCollection = (
    attributeCollectionId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeCollection>> => {
    
    return axios.default.get(
      `/api/atlas/attribute_collections/${attributeCollectionId}`,options
    );
  }


export const getReadAttributeCollectionQueryKey = (attributeCollectionId: number,) => {
    return [`/api/atlas/attribute_collections/${attributeCollectionId}`] as const;
    }

    
export const getReadAttributeCollectionQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeCollection>>, TError = AxiosError<HTTPValidationError>>(attributeCollectionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollection>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeCollectionQueryKey(attributeCollectionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeCollection>>> = ({ signal }) => readAttributeCollection(attributeCollectionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeCollectionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeCollection>>>
export type ReadAttributeCollectionQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute Collection
 */
export const useReadAttributeCollection = <TData = Awaited<ReturnType<typeof readAttributeCollection>>, TError = AxiosError<HTTPValidationError>>(
 attributeCollectionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollection>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeCollectionQueryOptions(attributeCollectionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Attribute Collection
 */
export const updateAttributeCollection = (
    attributeCollectionId: number,
    attributeCollection: AttributeCollection, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeCollection>> => {
    
    return axios.default.put(
      `/api/atlas/attribute_collections/${attributeCollectionId}`,
      attributeCollection,options
    );
  }



export const getUpdateAttributeCollectionMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAttributeCollection>>, TError,{attributeCollectionId: number;data: AttributeCollection}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateAttributeCollection>>, TError,{attributeCollectionId: number;data: AttributeCollection}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAttributeCollection>>, {attributeCollectionId: number;data: AttributeCollection}> = (props) => {
          const {attributeCollectionId,data} = props ?? {};

          return  updateAttributeCollection(attributeCollectionId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAttributeCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAttributeCollection>>>
    export type UpdateAttributeCollectionMutationBody = AttributeCollection
    export type UpdateAttributeCollectionMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Attribute Collection
 */
export const useUpdateAttributeCollection = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAttributeCollection>>, TError,{attributeCollectionId: number;data: AttributeCollection}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateAttributeCollection>>,
        TError,
        {attributeCollectionId: number;data: AttributeCollection},
        TContext
      > => {

      const mutationOptions = getUpdateAttributeCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Attribute Collection
 */
export const deleteAttributeCollection = (
    attributeCollectionId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/api/atlas/attribute_collections/${attributeCollectionId}`,options
    );
  }



export const getDeleteAttributeCollectionMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAttributeCollection>>, TError,{attributeCollectionId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAttributeCollection>>, TError,{attributeCollectionId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAttributeCollection>>, {attributeCollectionId: number}> = (props) => {
          const {attributeCollectionId} = props ?? {};

          return  deleteAttributeCollection(attributeCollectionId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAttributeCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAttributeCollection>>>
    
    export type DeleteAttributeCollectionMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Attribute Collection
 */
export const useDeleteAttributeCollection = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAttributeCollection>>, TError,{attributeCollectionId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAttributeCollection>>,
        TError,
        {attributeCollectionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteAttributeCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Collections By Ids
 */
export const readAttributeCollectionsByIds = (
    readAttributeCollectionsByIdsBody: number[],
    params?: ReadAttributeCollectionsByIdsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollectionLight>> => {
    
    return axios.default.post(
      `/api/atlas/attribute_collections_by_ids`,
      readAttributeCollectionsByIdsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getReadAttributeCollectionsByIdsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readAttributeCollectionsByIds>>, TError,{data: number[];params?: ReadAttributeCollectionsByIdsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof readAttributeCollectionsByIds>>, TError,{data: number[];params?: ReadAttributeCollectionsByIdsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof readAttributeCollectionsByIds>>, {data: number[];params?: ReadAttributeCollectionsByIdsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  readAttributeCollectionsByIds(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReadAttributeCollectionsByIdsMutationResult = NonNullable<Awaited<ReturnType<typeof readAttributeCollectionsByIds>>>
    export type ReadAttributeCollectionsByIdsMutationBody = number[]
    export type ReadAttributeCollectionsByIdsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Read Attribute Collections By Ids
 */
export const useReadAttributeCollectionsByIds = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readAttributeCollectionsByIds>>, TError,{data: number[];params?: ReadAttributeCollectionsByIdsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof readAttributeCollectionsByIds>>,
        TError,
        {data: number[];params?: ReadAttributeCollectionsByIdsParams},
        TContext
      > => {

      const mutationOptions = getReadAttributeCollectionsByIdsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Is Attribute Collection Owned By User
 */
export const isAttributeCollectionOwnedByUser = (
    attributeCollectionId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/api/atlas/attribute_collections_is_owned/${attributeCollectionId}`,options
    );
  }


export const getIsAttributeCollectionOwnedByUserQueryKey = (attributeCollectionId: number,) => {
    return [`/api/atlas/attribute_collections_is_owned/${attributeCollectionId}`] as const;
    }

    
export const getIsAttributeCollectionOwnedByUserQueryOptions = <TData = Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(attributeCollectionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsAttributeCollectionOwnedByUserQueryKey(attributeCollectionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>> = ({ signal }) => isAttributeCollectionOwnedByUser(attributeCollectionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeCollectionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type IsAttributeCollectionOwnedByUserQueryResult = NonNullable<Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>>
export type IsAttributeCollectionOwnedByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Is Attribute Collection Owned By User
 */
export const useIsAttributeCollectionOwnedByUser = <TData = Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(
 attributeCollectionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isAttributeCollectionOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsAttributeCollectionOwnedByUserQueryOptions(attributeCollectionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Attribute Collections By Attribute
 */
export const readAttributeCollectionsByAttribute = (
    attributeId: number,
    params?: ReadAttributeCollectionsByAttributeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollection>> => {
    
    return axios.default.get(
      `/api/atlas/attribute_collections_by_attribute/${attributeId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadAttributeCollectionsByAttributeQueryKey = (attributeId: number,
    params?: ReadAttributeCollectionsByAttributeParams,) => {
    return [`/api/atlas/attribute_collections_by_attribute/${attributeId}`, ...(params ? [params]: [])] as const;
    }

    
export const getReadAttributeCollectionsByAttributeQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>, TError = AxiosError<HTTPValidationError>>(attributeId: number,
    params?: ReadAttributeCollectionsByAttributeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeCollectionsByAttributeQueryKey(attributeId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>> = ({ signal }) => readAttributeCollectionsByAttribute(attributeId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeCollectionsByAttributeQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>>
export type ReadAttributeCollectionsByAttributeQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute Collections By Attribute
 */
export const useReadAttributeCollectionsByAttribute = <TData = Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>, TError = AxiosError<HTTPValidationError>>(
 attributeId: number,
    params?: ReadAttributeCollectionsByAttributeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByAttribute>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeCollectionsByAttributeQueryOptions(attributeId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Search Attribute Collections By Text
 */
export const searchAttributeCollectionsByText = (
    params: SearchAttributeCollectionsByTextParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollectionLight>> => {
    
    return axios.default.post(
      `/api/atlas/attribute_collections/text_search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchAttributeCollectionsByTextMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByText>>, TError,{params: SearchAttributeCollectionsByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByText>>, TError,{params: SearchAttributeCollectionsByTextParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchAttributeCollectionsByText>>, {params: SearchAttributeCollectionsByTextParams}> = (props) => {
          const {params} = props ?? {};

          return  searchAttributeCollectionsByText(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchAttributeCollectionsByTextMutationResult = NonNullable<Awaited<ReturnType<typeof searchAttributeCollectionsByText>>>
    
    export type SearchAttributeCollectionsByTextMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Attribute Collections By Text
 */
export const useSearchAttributeCollectionsByText = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByText>>, TError,{params: SearchAttributeCollectionsByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchAttributeCollectionsByText>>,
        TError,
        {params: SearchAttributeCollectionsByTextParams},
        TContext
      > => {

      const mutationOptions = getSearchAttributeCollectionsByTextMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Attribute Collections By Tags
 */
export const searchAttributeCollectionsByTags = (
    searchAttributeCollectionsByTagsBody: number[],
    params?: SearchAttributeCollectionsByTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollectionLight>> => {
    
    return axios.default.post(
      `/api/atlas/attribute_collections/tag_search`,
      searchAttributeCollectionsByTagsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchAttributeCollectionsByTagsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>, TError,{data: number[];params?: SearchAttributeCollectionsByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>, TError,{data: number[];params?: SearchAttributeCollectionsByTagsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>, {data: number[];params?: SearchAttributeCollectionsByTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  searchAttributeCollectionsByTags(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchAttributeCollectionsByTagsMutationResult = NonNullable<Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>>
    export type SearchAttributeCollectionsByTagsMutationBody = number[]
    export type SearchAttributeCollectionsByTagsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Attribute Collections By Tags
 */
export const useSearchAttributeCollectionsByTags = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>, TError,{data: number[];params?: SearchAttributeCollectionsByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchAttributeCollectionsByTags>>,
        TError,
        {data: number[];params?: SearchAttributeCollectionsByTagsParams},
        TContext
      > => {

      const mutationOptions = getSearchAttributeCollectionsByTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Add Attribute To Collection
 */
export const addAttributeToCollection = (
    attributeCollectionId: number,
    params: AddAttributeToCollectionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeCollectionLight>> => {
    
    return axios.default.post(
      `/api/atlas/attribute_collections/${attributeCollectionId}/add_attribute`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getAddAttributeToCollectionMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addAttributeToCollection>>, TError,{attributeCollectionId: number;params: AddAttributeToCollectionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addAttributeToCollection>>, TError,{attributeCollectionId: number;params: AddAttributeToCollectionParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addAttributeToCollection>>, {attributeCollectionId: number;params: AddAttributeToCollectionParams}> = (props) => {
          const {attributeCollectionId,params} = props ?? {};

          return  addAttributeToCollection(attributeCollectionId,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddAttributeToCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof addAttributeToCollection>>>
    
    export type AddAttributeToCollectionMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Attribute To Collection
 */
export const useAddAttributeToCollection = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addAttributeToCollection>>, TError,{attributeCollectionId: number;params: AddAttributeToCollectionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addAttributeToCollection>>,
        TError,
        {attributeCollectionId: number;params: AddAttributeToCollectionParams},
        TContext
      > => {

      const mutationOptions = getAddAttributeToCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Collections By User
 */
export const readAttributeCollectionsByUser = (
    params?: ReadAttributeCollectionsByUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseAttributeCollectionLight>> => {
    
    return axios.default.get(
      `/api/atlas/attribute_collections_of_user`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadAttributeCollectionsByUserQueryKey = (params?: ReadAttributeCollectionsByUserParams,) => {
    return [`/api/atlas/attribute_collections_of_user`, ...(params ? [params]: [])] as const;
    }

    
export const getReadAttributeCollectionsByUserQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeCollectionsByUser>>, TError = AxiosError<HTTPValidationError>>(params?: ReadAttributeCollectionsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeCollectionsByUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeCollectionsByUser>>> = ({ signal }) => readAttributeCollectionsByUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeCollectionsByUserQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeCollectionsByUser>>>
export type ReadAttributeCollectionsByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Attribute Collections By User
 */
export const useReadAttributeCollectionsByUser = <TData = Awaited<ReturnType<typeof readAttributeCollectionsByUser>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadAttributeCollectionsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeCollectionsByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadAttributeCollectionsByUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create Dataset
 */
export const createDataset = (
    datasetCreate: DatasetCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Dataset>> => {
    
    return axios.default.post(
      `/api/atlas/datasets`,
      datasetCreate,options
    );
  }



export const getCreateDatasetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataset>>, TError,{data: DatasetCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createDataset>>, TError,{data: DatasetCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataset>>, {data: DatasetCreate}> = (props) => {
          const {data} = props ?? {};

          return  createDataset(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDatasetMutationResult = NonNullable<Awaited<ReturnType<typeof createDataset>>>
    export type CreateDatasetMutationBody = DatasetCreate
    export type CreateDatasetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Dataset
 */
export const useCreateDataset = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataset>>, TError,{data: DatasetCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createDataset>>,
        TError,
        {data: DatasetCreate},
        TContext
      > => {

      const mutationOptions = getCreateDatasetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Datasets
 */
export const readDatasets = (
    params?: ReadDatasetsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseDatasetLight>> => {
    
    return axios.default.get(
      `/api/atlas/datasets`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadDatasetsQueryKey = (params?: ReadDatasetsParams,) => {
    return [`/api/atlas/datasets`, ...(params ? [params]: [])] as const;
    }

    
export const getReadDatasetsQueryOptions = <TData = Awaited<ReturnType<typeof readDatasets>>, TError = AxiosError<HTTPValidationError>>(params?: ReadDatasetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDatasets>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadDatasetsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readDatasets>>> = ({ signal }) => readDatasets(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readDatasets>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadDatasetsQueryResult = NonNullable<Awaited<ReturnType<typeof readDatasets>>>
export type ReadDatasetsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Datasets
 */
export const useReadDatasets = <TData = Awaited<ReturnType<typeof readDatasets>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadDatasetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDatasets>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadDatasetsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Datasets By User
 */
export const readDatasetsByUser = (
    params?: ReadDatasetsByUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseDatasetLight>> => {
    
    return axios.default.get(
      `/api/atlas/datasets_of_user`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadDatasetsByUserQueryKey = (params?: ReadDatasetsByUserParams,) => {
    return [`/api/atlas/datasets_of_user`, ...(params ? [params]: [])] as const;
    }

    
export const getReadDatasetsByUserQueryOptions = <TData = Awaited<ReturnType<typeof readDatasetsByUser>>, TError = AxiosError<HTTPValidationError>>(params?: ReadDatasetsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDatasetsByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadDatasetsByUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readDatasetsByUser>>> = ({ signal }) => readDatasetsByUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readDatasetsByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadDatasetsByUserQueryResult = NonNullable<Awaited<ReturnType<typeof readDatasetsByUser>>>
export type ReadDatasetsByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Datasets By User
 */
export const useReadDatasetsByUser = <TData = Awaited<ReturnType<typeof readDatasetsByUser>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadDatasetsByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDatasetsByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadDatasetsByUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Dataset
 */
export const readDataset = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Dataset>> => {
    
    return axios.default.get(
      `/api/atlas/datasets/${datasetId}`,options
    );
  }


export const getReadDatasetQueryKey = (datasetId: number,) => {
    return [`/api/atlas/datasets/${datasetId}`] as const;
    }

    
export const getReadDatasetQueryOptions = <TData = Awaited<ReturnType<typeof readDataset>>, TError = AxiosError<HTTPValidationError>>(datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDataset>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadDatasetQueryKey(datasetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readDataset>>> = ({ signal }) => readDataset(datasetId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(datasetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readDataset>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadDatasetQueryResult = NonNullable<Awaited<ReturnType<typeof readDataset>>>
export type ReadDatasetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Dataset
 */
export const useReadDataset = <TData = Awaited<ReturnType<typeof readDataset>>, TError = AxiosError<HTTPValidationError>>(
 datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readDataset>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadDatasetQueryOptions(datasetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Dataset
 */
export const updateDataset = (
    datasetId: number,
    datasetLight: DatasetLight, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Dataset>> => {
    
    return axios.default.put(
      `/api/atlas/datasets/${datasetId}`,
      datasetLight,options
    );
  }



export const getUpdateDatasetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataset>>, TError,{datasetId: number;data: DatasetLight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataset>>, TError,{datasetId: number;data: DatasetLight}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataset>>, {datasetId: number;data: DatasetLight}> = (props) => {
          const {datasetId,data} = props ?? {};

          return  updateDataset(datasetId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDatasetMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataset>>>
    export type UpdateDatasetMutationBody = DatasetLight
    export type UpdateDatasetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Dataset
 */
export const useUpdateDataset = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataset>>, TError,{datasetId: number;data: DatasetLight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateDataset>>,
        TError,
        {datasetId: number;data: DatasetLight},
        TContext
      > => {

      const mutationOptions = getUpdateDatasetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Dataset
 */
export const deleteDataset = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/api/atlas/datasets/${datasetId}`,options
    );
  }



export const getDeleteDatasetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataset>>, TError,{datasetId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataset>>, {datasetId: number}> = (props) => {
          const {datasetId} = props ?? {};

          return  deleteDataset(datasetId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDatasetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataset>>>
    
    export type DeleteDatasetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Dataset
 */
export const useDeleteDataset = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteDataset>>,
        TError,
        {datasetId: number},
        TContext
      > => {

      const mutationOptions = getDeleteDatasetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Datasets By Text
 */
export const searchDatasetsByText = (
    params: SearchDatasetsByTextParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseDatasetLight>> => {
    
    return axios.default.post(
      `/api/atlas/datasets/text_search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchDatasetsByTextMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByText>>, TError,{params: SearchDatasetsByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByText>>, TError,{params: SearchDatasetsByTextParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchDatasetsByText>>, {params: SearchDatasetsByTextParams}> = (props) => {
          const {params} = props ?? {};

          return  searchDatasetsByText(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchDatasetsByTextMutationResult = NonNullable<Awaited<ReturnType<typeof searchDatasetsByText>>>
    
    export type SearchDatasetsByTextMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Datasets By Text
 */
export const useSearchDatasetsByText = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByText>>, TError,{params: SearchDatasetsByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchDatasetsByText>>,
        TError,
        {params: SearchDatasetsByTextParams},
        TContext
      > => {

      const mutationOptions = getSearchDatasetsByTextMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Datasets By Tags
 */
export const searchDatasetsByTags = (
    searchDatasetsByTagsBody: number[],
    params?: SearchDatasetsByTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseDatasetLight>> => {
    
    return axios.default.post(
      `/api/atlas/datasets/tag_search`,
      searchDatasetsByTagsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchDatasetsByTagsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByTags>>, TError,{data: number[];params?: SearchDatasetsByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByTags>>, TError,{data: number[];params?: SearchDatasetsByTagsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchDatasetsByTags>>, {data: number[];params?: SearchDatasetsByTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  searchDatasetsByTags(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchDatasetsByTagsMutationResult = NonNullable<Awaited<ReturnType<typeof searchDatasetsByTags>>>
    export type SearchDatasetsByTagsMutationBody = number[]
    export type SearchDatasetsByTagsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Datasets By Tags
 */
export const useSearchDatasetsByTags = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchDatasetsByTags>>, TError,{data: number[];params?: SearchDatasetsByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchDatasetsByTags>>,
        TError,
        {data: number[];params?: SearchDatasetsByTagsParams},
        TContext
      > => {

      const mutationOptions = getSearchDatasetsByTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Is Dataset Owned By User
 */
export const isDatasetOwnedByUser = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/api/atlas/datasets_is_owned/${datasetId}`,options
    );
  }


export const getIsDatasetOwnedByUserQueryKey = (datasetId: number,) => {
    return [`/api/atlas/datasets_is_owned/${datasetId}`] as const;
    }

    
export const getIsDatasetOwnedByUserQueryOptions = <TData = Awaited<ReturnType<typeof isDatasetOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isDatasetOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsDatasetOwnedByUserQueryKey(datasetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isDatasetOwnedByUser>>> = ({ signal }) => isDatasetOwnedByUser(datasetId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(datasetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isDatasetOwnedByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type IsDatasetOwnedByUserQueryResult = NonNullable<Awaited<ReturnType<typeof isDatasetOwnedByUser>>>
export type IsDatasetOwnedByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Is Dataset Owned By User
 */
export const useIsDatasetOwnedByUser = <TData = Awaited<ReturnType<typeof isDatasetOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(
 datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isDatasetOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsDatasetOwnedByUserQueryOptions(datasetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Is Dataset Favorited
 */
export const isDatasetFavorited = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/api/atlas/datasets/${datasetId}/favorite`,options
    );
  }


export const getIsDatasetFavoritedQueryKey = (datasetId: number,) => {
    return [`/api/atlas/datasets/${datasetId}/favorite`] as const;
    }

    
export const getIsDatasetFavoritedQueryOptions = <TData = Awaited<ReturnType<typeof isDatasetFavorited>>, TError = AxiosError<HTTPValidationError>>(datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isDatasetFavorited>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsDatasetFavoritedQueryKey(datasetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isDatasetFavorited>>> = ({ signal }) => isDatasetFavorited(datasetId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(datasetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isDatasetFavorited>>, TError, TData> & { queryKey: QueryKey }
}

export type IsDatasetFavoritedQueryResult = NonNullable<Awaited<ReturnType<typeof isDatasetFavorited>>>
export type IsDatasetFavoritedQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Is Dataset Favorited
 */
export const useIsDatasetFavorited = <TData = Awaited<ReturnType<typeof isDatasetFavorited>>, TError = AxiosError<HTTPValidationError>>(
 datasetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isDatasetFavorited>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsDatasetFavoritedQueryOptions(datasetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Favorite Dataset
 */
export const favoriteDataset = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.post(
      `/api/atlas/datasets/${datasetId}/favorite`,undefined,options
    );
  }



export const getFavoriteDatasetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof favoriteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof favoriteDataset>>, TError,{datasetId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof favoriteDataset>>, {datasetId: number}> = (props) => {
          const {datasetId} = props ?? {};

          return  favoriteDataset(datasetId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FavoriteDatasetMutationResult = NonNullable<Awaited<ReturnType<typeof favoriteDataset>>>
    
    export type FavoriteDatasetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Favorite Dataset
 */
export const useFavoriteDataset = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof favoriteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof favoriteDataset>>,
        TError,
        {datasetId: number},
        TContext
      > => {

      const mutationOptions = getFavoriteDatasetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Unfavorite Dataset
 */
export const unfavoriteDataset = (
    datasetId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/api/atlas/datasets/${datasetId}/favorite`,options
    );
  }



export const getUnfavoriteDatasetMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfavoriteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof unfavoriteDataset>>, TError,{datasetId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unfavoriteDataset>>, {datasetId: number}> = (props) => {
          const {datasetId} = props ?? {};

          return  unfavoriteDataset(datasetId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UnfavoriteDatasetMutationResult = NonNullable<Awaited<ReturnType<typeof unfavoriteDataset>>>
    
    export type UnfavoriteDatasetMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Unfavorite Dataset
 */
export const useUnfavoriteDataset = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfavoriteDataset>>, TError,{datasetId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof unfavoriteDataset>>,
        TError,
        {datasetId: number},
        TContext
      > => {

      const mutationOptions = getUnfavoriteDatasetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Favorite Datasets
 */
export const readFavoriteDatasets = (
    params?: ReadFavoriteDatasetsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseDatasetLight>> => {
    
    return axios.default.get(
      `/api/atlas/dataset_favorites`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadFavoriteDatasetsQueryKey = (params?: ReadFavoriteDatasetsParams,) => {
    return [`/api/atlas/dataset_favorites`, ...(params ? [params]: [])] as const;
    }

    
export const getReadFavoriteDatasetsQueryOptions = <TData = Awaited<ReturnType<typeof readFavoriteDatasets>>, TError = AxiosError<HTTPValidationError>>(params?: ReadFavoriteDatasetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readFavoriteDatasets>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadFavoriteDatasetsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readFavoriteDatasets>>> = ({ signal }) => readFavoriteDatasets(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readFavoriteDatasets>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadFavoriteDatasetsQueryResult = NonNullable<Awaited<ReturnType<typeof readFavoriteDatasets>>>
export type ReadFavoriteDatasetsQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Favorite Datasets
 */
export const useReadFavoriteDatasets = <TData = Awaited<ReturnType<typeof readFavoriteDatasets>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadFavoriteDatasetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readFavoriteDatasets>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadFavoriteDatasetsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create Sample
 */
export const createSample = (
    sampleCreate: SampleCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Sample>> => {
    
    return axios.default.post(
      `/api/atlas/samples`,
      sampleCreate,options
    );
  }



export const getCreateSampleMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSample>>, TError,{data: SampleCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createSample>>, TError,{data: SampleCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSample>>, {data: SampleCreate}> = (props) => {
          const {data} = props ?? {};

          return  createSample(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateSampleMutationResult = NonNullable<Awaited<ReturnType<typeof createSample>>>
    export type CreateSampleMutationBody = SampleCreate
    export type CreateSampleMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Sample
 */
export const useCreateSample = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSample>>, TError,{data: SampleCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createSample>>,
        TError,
        {data: SampleCreate},
        TContext
      > => {

      const mutationOptions = getCreateSampleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Samples
 */
export const readSamples = (
    params?: ReadSamplesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSample>> => {
    
    return axios.default.get(
      `/api/atlas/samples`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadSamplesQueryKey = (params?: ReadSamplesParams,) => {
    return [`/api/atlas/samples`, ...(params ? [params]: [])] as const;
    }

    
export const getReadSamplesQueryOptions = <TData = Awaited<ReturnType<typeof readSamples>>, TError = AxiosError<HTTPValidationError>>(params?: ReadSamplesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSamples>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadSamplesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readSamples>>> = ({ signal }) => readSamples(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readSamples>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadSamplesQueryResult = NonNullable<Awaited<ReturnType<typeof readSamples>>>
export type ReadSamplesQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Samples
 */
export const useReadSamples = <TData = Awaited<ReturnType<typeof readSamples>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadSamplesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSamples>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadSamplesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Read Sample
 */
export const readSample = (
    sampleId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Sample>> => {
    
    return axios.default.get(
      `/api/atlas/samples/${sampleId}`,options
    );
  }


export const getReadSampleQueryKey = (sampleId: number,) => {
    return [`/api/atlas/samples/${sampleId}`] as const;
    }

    
export const getReadSampleQueryOptions = <TData = Awaited<ReturnType<typeof readSample>>, TError = AxiosError<HTTPValidationError>>(sampleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSample>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadSampleQueryKey(sampleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readSample>>> = ({ signal }) => readSample(sampleId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(sampleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readSample>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadSampleQueryResult = NonNullable<Awaited<ReturnType<typeof readSample>>>
export type ReadSampleQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Sample
 */
export const useReadSample = <TData = Awaited<ReturnType<typeof readSample>>, TError = AxiosError<HTTPValidationError>>(
 sampleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSample>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadSampleQueryOptions(sampleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Update Sample
 */
export const updateSample = (
    sampleId: number,
    sampleLight: SampleLight, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Sample>> => {
    
    return axios.default.put(
      `/api/atlas/samples/${sampleId}`,
      sampleLight,options
    );
  }



export const getUpdateSampleMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSample>>, TError,{sampleId: number;data: SampleLight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateSample>>, TError,{sampleId: number;data: SampleLight}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSample>>, {sampleId: number;data: SampleLight}> = (props) => {
          const {sampleId,data} = props ?? {};

          return  updateSample(sampleId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSampleMutationResult = NonNullable<Awaited<ReturnType<typeof updateSample>>>
    export type UpdateSampleMutationBody = SampleLight
    export type UpdateSampleMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Sample
 */
export const useUpdateSample = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSample>>, TError,{sampleId: number;data: SampleLight}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateSample>>,
        TError,
        {sampleId: number;data: SampleLight},
        TContext
      > => {

      const mutationOptions = getUpdateSampleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Delete Sample
 */
export const deleteSample = (
    sampleId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.delete(
      `/api/atlas/samples/${sampleId}`,options
    );
  }



export const getDeleteSampleMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSample>>, TError,{sampleId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteSample>>, TError,{sampleId: number}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSample>>, {sampleId: number}> = (props) => {
          const {sampleId} = props ?? {};

          return  deleteSample(sampleId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteSampleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSample>>>
    
    export type DeleteSampleMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Sample
 */
export const useDeleteSample = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSample>>, TError,{sampleId: number}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteSample>>,
        TError,
        {sampleId: number},
        TContext
      > => {

      const mutationOptions = getDeleteSampleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Samples By Text
 */
export const searchSamplesByText = (
    params: SearchSamplesByTextParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSampleLight>> => {
    
    return axios.default.post(
      `/api/atlas/samples/text_search`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchSamplesByTextMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByText>>, TError,{params: SearchSamplesByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByText>>, TError,{params: SearchSamplesByTextParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchSamplesByText>>, {params: SearchSamplesByTextParams}> = (props) => {
          const {params} = props ?? {};

          return  searchSamplesByText(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchSamplesByTextMutationResult = NonNullable<Awaited<ReturnType<typeof searchSamplesByText>>>
    
    export type SearchSamplesByTextMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Samples By Text
 */
export const useSearchSamplesByText = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByText>>, TError,{params: SearchSamplesByTextParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchSamplesByText>>,
        TError,
        {params: SearchSamplesByTextParams},
        TContext
      > => {

      const mutationOptions = getSearchSamplesByTextMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Search Samples By Tags
 */
export const searchSamplesByTags = (
    searchSamplesByTagsBody: number[],
    params?: SearchSamplesByTagsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSampleLight>> => {
    
    return axios.default.post(
      `/api/atlas/samples/tag_search`,
      searchSamplesByTagsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getSearchSamplesByTagsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByTags>>, TError,{data: number[];params?: SearchSamplesByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByTags>>, TError,{data: number[];params?: SearchSamplesByTagsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchSamplesByTags>>, {data: number[];params?: SearchSamplesByTagsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  searchSamplesByTags(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchSamplesByTagsMutationResult = NonNullable<Awaited<ReturnType<typeof searchSamplesByTags>>>
    export type SearchSamplesByTagsMutationBody = number[]
    export type SearchSamplesByTagsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Samples By Tags
 */
export const useSearchSamplesByTags = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchSamplesByTags>>, TError,{data: number[];params?: SearchSamplesByTagsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchSamplesByTags>>,
        TError,
        {data: number[];params?: SearchSamplesByTagsParams},
        TContext
      > => {

      const mutationOptions = getSearchSamplesByTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Samples By Ids
 */
export const readSamplesByIds = (
    readSamplesByIdsBody: number[],
    params?: ReadSamplesByIdsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSample>> => {
    
    return axios.default.post(
      `/api/atlas/samples_by_ids`,
      readSamplesByIdsBody,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getReadSamplesByIdsMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readSamplesByIds>>, TError,{data: number[];params?: ReadSamplesByIdsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof readSamplesByIds>>, TError,{data: number[];params?: ReadSamplesByIdsParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof readSamplesByIds>>, {data: number[];params?: ReadSamplesByIdsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  readSamplesByIds(data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReadSamplesByIdsMutationResult = NonNullable<Awaited<ReturnType<typeof readSamplesByIds>>>
    export type ReadSamplesByIdsMutationBody = number[]
    export type ReadSamplesByIdsMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Read Samples By Ids
 */
export const useReadSamplesByIds = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof readSamplesByIds>>, TError,{data: number[];params?: ReadSamplesByIdsParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof readSamplesByIds>>,
        TError,
        {data: number[];params?: ReadSamplesByIdsParams},
        TContext
      > => {

      const mutationOptions = getReadSamplesByIdsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Samples By User
 */
export const readSamplesByUser = (
    params?: ReadSamplesByUserParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedResponseSampleLight>> => {
    
    return axios.default.get(
      `/api/atlas/samples_of_user`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getReadSamplesByUserQueryKey = (params?: ReadSamplesByUserParams,) => {
    return [`/api/atlas/samples_of_user`, ...(params ? [params]: [])] as const;
    }

    
export const getReadSamplesByUserQueryOptions = <TData = Awaited<ReturnType<typeof readSamplesByUser>>, TError = AxiosError<HTTPValidationError>>(params?: ReadSamplesByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSamplesByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadSamplesByUserQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readSamplesByUser>>> = ({ signal }) => readSamplesByUser(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readSamplesByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadSamplesByUserQueryResult = NonNullable<Awaited<ReturnType<typeof readSamplesByUser>>>
export type ReadSamplesByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Read Samples By User
 */
export const useReadSamplesByUser = <TData = Awaited<ReturnType<typeof readSamplesByUser>>, TError = AxiosError<HTTPValidationError>>(
 params?: ReadSamplesByUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readSamplesByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReadSamplesByUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Is Sample Owned By User
 */
export const isSampleOwnedByUser = (
    sampleId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.default.get(
      `/api/atlas/samples_is_owned/${sampleId}`,options
    );
  }


export const getIsSampleOwnedByUserQueryKey = (sampleId: number,) => {
    return [`/api/atlas/samples_is_owned/${sampleId}`] as const;
    }

    
export const getIsSampleOwnedByUserQueryOptions = <TData = Awaited<ReturnType<typeof isSampleOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(sampleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isSampleOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsSampleOwnedByUserQueryKey(sampleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isSampleOwnedByUser>>> = ({ signal }) => isSampleOwnedByUser(sampleId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(sampleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isSampleOwnedByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type IsSampleOwnedByUserQueryResult = NonNullable<Awaited<ReturnType<typeof isSampleOwnedByUser>>>
export type IsSampleOwnedByUserQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Is Sample Owned By User
 */
export const useIsSampleOwnedByUser = <TData = Awaited<ReturnType<typeof isSampleOwnedByUser>>, TError = AxiosError<HTTPValidationError>>(
 sampleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isSampleOwnedByUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsSampleOwnedByUserQueryOptions(sampleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



