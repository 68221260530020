import DatasetIcon from '@mui/icons-material/Dataset'
import ListIcon from '@mui/icons-material/List'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import SearchIcon from '@mui/icons-material/Search'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@northvolt/ui'
import { matchSorter } from 'match-sorter'
import React, { useEffect, useState } from 'react'
import ResultAccordion from './ResultAccordion'

import {
  parseAsArrayOf,
  parseAsInteger,
  useQueryParam,
} from 'components/useQueryHook'

import {
  useSearchAttributeCollectionsByTags,
  useSearchAttributesByTags,
  useSearchDatasetsByTags,
  useSearchRemainingSearchTags,
  useSearchSamplesByTags,
} from 'client/atlas/atlas'
import type {
  Attribute,
  AttributeCollectionLight,
  DatasetLight,
  SampleLight,
  SearchTag,
} from 'client/model'
import AttributeListItem from 'components/Attribute/AttributeListItem'
import AttributeCollectionListItem from 'components/AttributeCollection/AttributeCollectionListItem'
import DatasetListItem from 'components/Dataset/DatasetListItem'
import SampleListItem from 'components/Sample/SampleListItem'
import SearchTagChip from 'components/SearchTag/SearchTagChip'
import SearchTagListItem from 'components/SearchTag/SearchTagListItem'
import useDeepCompareEffect from 'use-deep-compare-effect'

export default function Route() {
  const [searchTagIds, setSearchTagIds] = useQueryParam(
    'tagIds',
    [],
    parseAsArrayOf(parseAsInteger),
  )
  const [searchTags, setSearchTags] = useState<SearchTag[]>([])
  const searchTagSearch = useSearchRemainingSearchTags()

  const [expandedAccordion, setExpandedAccordion] = React.useState<
    string | false
  >(false)

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false)
    }

  const updateSelectedTags = () => {
    const tags = searchTagIds.map((id: number) =>
      searchTagSearch.data?.data.items.find(tag => tag.id === id),
    )
    setSearchTags(tags)
  }

  useDeepCompareEffect(() => {
    updateSelectedTags()
    setExpandedAccordion(false)
    searchTagSearch.mutate({ data: [], params: { limit: 20000 } })
  }, [searchTagIds])

  useEffect(() => {
    if (searchTagSearch.isSuccess) {
      updateSelectedTags()
    }
  }, [searchTagSearch.data?.data])

  const MAX_FILTER_OPTIONS = 300
  function filterOptions(
    options: any[],
    { inputValue }: { inputValue: string },
  ) {
    const terms = (inputValue as string)?.split(' ')
    if (!terms || !(typeof terms === 'object')) {
      return options
    } else if (inputValue === '') {
      const suggestions = options.filter(
        tag =>
          searchTagIds.find((id: number) => id === tag.id) ||
          !['Author', 'Name'].includes(tag.key),
      )
      return suggestions
        .sort((a, b) => a.key.localeCompare(b.key))
        .slice(0, MAX_FILTER_OPTIONS)
    } else {
      const filterFunction = (tags: any[], filterVal: string) =>
        matchSorter(tags, filterVal, {
          keys: ['key', tag => tag.value.replace(/_/g, ' ')],
        })

      const results = terms.reduceRight(
        (results, term) => filterFunction(results, term),
        options,
      )
      return results.slice(0, MAX_FILTER_OPTIONS)
    }
  }

  const getValue = () => {
    return searchTags.filter(tag => tag !== undefined)
  }

  return (
    <Stack sx={{ height: '100%', overflowY: 'auto' }} spacing={1}>
      <Paper elevation={1} sx={{ p: 1.5, pb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
          <SearchIcon sx={{ mr: 1 }} />
          <Typography variant='headlineSmall'>Search</Typography>
        </Box>
        <Autocomplete
          multiple
          autoHighlight
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          size='small'
          value={getValue()}
          options={searchTagSearch.data?.data?.items || []}
          onChange={(_: any, newValue: any) => {
            setSearchTagIds(newValue.map((tag: SearchTag) => tag.id))
          }}
          filterOptions={filterOptions}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.id === value?.id
          }
          disableListWrap
          renderOption={(props, option: any, { inputValue }) => (
            <SearchTagListItem
              key={option.id ? option.id : Math.random()}
              searchTag={option}
              listItemProps={props}
              inputValue={inputValue}
            />
          )}
          getOptionLabel={(option: any) => `${option.key}: ${option.value}`}
          renderInput={params => (
            <TextField {...params} label='Type to start searching' />
          )}
          renderTags={(value: any, getTagProps: any) =>
            value.map((option: any, index: number) => {
              if (option !== undefined) {
                return (
                  <SearchTagChip
                    key={option.id}
                    searchTag={option}
                    chipProps={getTagProps({ index })}
                  />
                )
              } else if (searchTagSearch.isError) {
                return (
                  <Chip
                    key={index}
                    label='Broken tag :('
                    size='small'
                    color='error'
                    variant='outlined'
                  />
                )
              } else {
                return (
                  <Chip
                    size='small'
                    label='Loading tag...'
                    disabled
                    variant='outlined'
                  />
                )
              }
            })
          }
        />
      </Paper>
      {searchTagIds.length > 0 && (
        <>
          <ResultAccordion
            searchTagIds={searchTagIds}
            expanded={expandedAccordion === 'datasets'}
            onChange={handleChange('datasets')}
            name='dataset'
            title='Datasets'
            tagSearch={useSearchDatasetsByTags}
            getResultItem={(r: DatasetLight) => <DatasetListItem dataset={r} />}
            itemsPerPage={6}
            icon={<DatasetIcon />}
            delay={0.1}
          />
          <ResultAccordion
            searchTagIds={searchTagIds}
            expanded={expandedAccordion === 'samples'}
            onChange={handleChange('samples')}
            name='sample'
            title='Samples'
            tagSearch={useSearchSamplesByTags}
            getResultItem={(r: SampleLight) => <SampleListItem sample={r} />}
            itemsPerPage={6}
            icon={<ScatterPlotIcon />}
            delay={0.2}
          />
          <ResultAccordion
            searchTagIds={searchTagIds}
            expanded={expandedAccordion === 'attrColl'}
            onChange={handleChange('attrColl')}
            name='attrColl'
            title='Attribute Collections'
            tagSearch={useSearchAttributeCollectionsByTags}
            getResultItem={(r: AttributeCollectionLight) => (
              <AttributeCollectionListItem attributeCollection={r} minimal />
            )}
            itemsPerPage={6}
            icon={<ListIcon />}
            delay={0.3}
          />
          <ResultAccordion
            searchTagIds={searchTagIds}
            expanded={expandedAccordion === 'attributes'}
            onChange={handleChange('attributes')}
            name='attributes'
            title='Attributes'
            tagSearch={useSearchAttributesByTags}
            getResultItem={(r: Attribute) => (
              <AttributeListItem attribute={r} minimal />
            )}
            itemsPerPage={12}
            icon={<ViewColumnIcon />}
            delay={0.4}
          />
        </>
      )}
    </Stack>
  )
}
