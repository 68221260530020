import BoltIcon from '@mui/icons-material/Bolt'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, CircularProgress, Menu, MenuItem } from '@northvolt/ui'
import { AssistantEnum } from 'client/model'
import { useState } from 'react'
import { type GigaWattsonHook, useGigaWattson } from './GigaWattsonHook'

import { motion } from 'framer-motion'
import React from 'react'
import Markdown from 'react-markdown'
import { Link } from 'react-router-dom'
import rehypeHighlight from 'rehype-highlight'
import remarkFrontmatter from 'remark-frontmatter'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'

export interface GigaWattsonPrompt {
  prompt: string
  assistant: AssistantEnum
  label: string
  icon: React.ReactNode
}

interface AskGigaWattsonProps {
  prompts: GigaWattsonPrompt[]
  fullWidth?: boolean
}

const AskGigaWattson: React.FC<AskGigaWattsonProps> = ({
  prompts,
  fullWidth,
}) => {
  const [gigaPrompt, setGigaPrompt] = useState<string | undefined>(undefined)
  const gigaWattson = useGigaWattson(AssistantEnum.factory_copilot, gigaPrompt)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        startIcon={<BoltIcon />}
        endIcon={
          gigaWattson.isLoading ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            <ExpandMoreIcon />
          )
        }
        variant='outlined'
        size='small'
        onClick={handleClick}
        fullWidth={fullWidth}>
        Ask GigaWattson
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {prompts.map(prompt => (
          <MenuItem
            dense
            key={prompt.label}
            onClick={() => {
              setGigaPrompt(prompt.prompt)
              handleClose()
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {prompt.icon}
              <Box sx={{ ml: 1 }}>{prompt.label}</Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <GigaWattsonAnswer gigaWattson={gigaWattson} />
    </Box>
  )
}

interface GigaWattsonAnswerProps {
  gigaWattson: GigaWattsonHook
}

export const GigaWattsonAnswer: React.FC<GigaWattsonAnswerProps> = ({
  gigaWattson,
}) => {
  const getContent = () => {
    if (gigaWattson.isError) {
      return <Box>Error: {gigaWattson.error?.message}</Box>
    } else if (gigaWattson.isSuccessful && gigaWattson.response) {
      return (
        <div style={{ fontSize: '0.8em' }}>
          <Markdown
            skipHtml={true}
            remarkPlugins={[
              remarkParse,
              remarkFrontmatter,
              remarkGfm,
              remarkRehype,
            ]}
            rehypePlugins={[rehypeHighlight]}>
            {gigaWattson.response}
          </Markdown>
        </div>
      )
    }
  }

  if (gigaWattson.prompt === undefined || gigaWattson.isLoading) {
    return <></>
  } else {
    let romeUrl = 'https://rome.ai.northvolt.com/'
    if (
      window.location.href.split('//')[1].startsWith('verona.northvolt.dev')
    ) {
      romeUrl = 'https://rome.northvolt.dev/'
    }

    return (
      <Box sx={{ mt: 2, border: '1px solid #E0E0E0', borderRadius: 1, p: 2 }}>
        <motion.div
          initial={{ opacity: 0, translateY: -30 }}
          animate={{ translateY: 0, opacity: 1, from: 0, to: 1 }}
          transition={{ ease: 'easeIn' }}
          style={{ overflowY: 'scroll' }}>
          {getContent()}
        </motion.div>
        <Link to={romeUrl} style={{ color: 'inherit' }} target='_blank'>
          Continue the conversation in Rome
        </Link>
      </Box>
    )
  }
}

export default AskGigaWattson
