import BookIcon from '@mui/icons-material/Book'
import { Grid, Stack } from '@mui/material'
import { Box, Button, Divider, Loader, Paper, Typography } from '@northvolt/ui'
import { Link } from 'react-router-dom'

import {
  useReadAttributeCollectionsByUser,
  useReadDatasetsByUser,
  useReadFavoriteDatasets,
  useReadSamplesByUser,
} from 'client/atlas/atlas'
import type { AttributeCollection, Dataset, Sample } from 'client/model'
import AttributeCollectionCard from 'components/AttributeCollection/AttributeCollectionCard'
import DatasetCard from 'components/Dataset/DatasetCard'
import SampleCard from 'components/Sample/SampleCard'

export default function Route() {
  const favoriteDatasetsLoader = useReadFavoriteDatasets()
  const userDatasetsLoader = useReadDatasetsByUser()
  const userSamplesLoader = useReadSamplesByUser()
  const userAttributeCollectionsLoader = useReadAttributeCollectionsByUser()

  const datasetSection = (datasetLoader: any, emptyLabel: string) => {
    if (datasetLoader.isLoading) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Loader type='linear' size='large' />
          </Box>
        </Grid>
      )
    }
    if (datasetLoader.isError) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography variant='overline'>
                Error downloading data:
              </Typography>
              <Typography variant='bodySmall'>
                {datasetLoader.failureReason.toString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )
    }
    if (!datasetLoader.data.data.items.length) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='overline'>{emptyLabel}</Typography>
          </Box>
        </Grid>
      )
    }
    return (
      <>
        {datasetLoader?.data.data.items
          .sort((a: Dataset, b: Dataset) => a.name.localeCompare(b.name))
          .map((dataset: Dataset) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={dataset.id}>
              <DatasetCard dataset={dataset} />
            </Grid>
          ))}
      </>
    )
  }

  const sampleSection = (sampleLoader: any, emptyLabel: string) => {
    if (sampleLoader.isLoading) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Loader type='linear' size='large' />
          </Box>
        </Grid>
      )
    }
    if (sampleLoader.isError) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography variant='overline'>
                Error downloading data:
              </Typography>
              <Typography variant='bodySmall'>
                {sampleLoader.failureReason.toString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )
    }
    if (!sampleLoader.data.data.items.length) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='overline'>{emptyLabel}</Typography>
          </Box>
        </Grid>
      )
    }
    return (
      <>
        {sampleLoader?.data.data.items
          .sort((a: Sample, b: Sample) => a.name.localeCompare(b.name))
          .map((sample: Sample) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={sample.id}>
              <SampleCard sample={sample} />
            </Grid>
          ))}
      </>
    )
  }

  const attributeCollectionSection = (
    attributeCollectionLoader: any,
    emptyLabel: string,
  ) => {
    if (attributeCollectionLoader.isLoading) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Loader type='linear' size='large' />
          </Box>
        </Grid>
      )
    }
    if (attributeCollectionLoader.isError) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Typography variant='overline'>
                Error downloading data:
              </Typography>
              <Typography variant='bodySmall'>
                {attributeCollectionLoader.failureReason.toString()}
              </Typography>
            </Box>
          </Box>
        </Grid>
      )
    }
    if (!attributeCollectionLoader.data.data.items.length) {
      return (
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='overline'>{emptyLabel}</Typography>
          </Box>
        </Grid>
      )
    }
    return (
      <>
        {attributeCollectionLoader?.data.data.items
          .sort((a: AttributeCollection, b: AttributeCollection) =>
            a.name.localeCompare(b.name),
          )
          .map((attributeCollection: AttributeCollection) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              xl={3}
              key={attributeCollection.id}>
              <AttributeCollectionCard
                attributeCollection={attributeCollection}
              />
            </Grid>
          ))}
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={1} sx={{ p: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <BookIcon sx={{ mr: 1 }} />
            <Typography variant='headlineMedium'>My Data</Typography>
          </Box>
          <Typography variant='bodyMedium'>
            Organize attributes into datasets.
          </Typography>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <Link to='/atlas/datasets/new'>
              <Button size='small'>Create Dataset</Button>
            </Link>
            <Link to='/atlas/attribute-collections/new'>
              <Button size='small'>Create Attribute Collection</Button>
            </Link>
            <Link to='/atlas/samples/new'>
              <Button size='small'>Create Sample</Button>
            </Link>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <Typography variant='headlineSmall'>Favorites</Typography>
        </Divider>
      </Grid>
      {datasetSection(favoriteDatasetsLoader, 'You have no favorites yet')}
      <Grid item xs={12}>
        <Divider>
          <Typography variant='headlineSmall'>My Datasets</Typography>
        </Divider>
      </Grid>
      {datasetSection(
        userDatasetsLoader,
        'You have not created any datasets yet',
      )}
      <Grid item xs={12}>
        <Divider>
          <Typography variant='headlineSmall'>My Samples</Typography>
        </Divider>
      </Grid>
      {sampleSection(userSamplesLoader, 'You have not created any samples yet')}
      <Grid item xs={12}>
        <Divider>
          <Typography variant='headlineSmall'>
            My Attribute Collections
          </Typography>
        </Divider>
      </Grid>
      {attributeCollectionSection(
        userAttributeCollectionsLoader,
        'You have not created any attribute collections yet',
      )}
      <Grid item xs={12}>
        <Divider>
          <Typography variant='headlineSmall'>
            My Uploaded .csv Files
          </Typography>
        </Divider>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='center'>
            <Typography variant='overline'>{'Coming soon'}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
