import ExpandMore from '@mui/icons-material/ExpandMore'
import Troubleshoot from '@mui/icons-material/Troubleshoot'
import { Accordion, AccordionSummary, Box, Typography } from '@northvolt/ui'
import CorrelationFinderForm from './CorrelationFinderForm'

export default () => (
  <Accordion expanded={true} disableGutters>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
        <Troubleshoot />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant='headlineSmall'>Correlation Finder</Typography>
      </Box>
    </AccordionSummary>
    <Box sx={{ px: 2, pb: 2 }}>
      <CorrelationFinderForm />
    </Box>
  </Accordion>
)
