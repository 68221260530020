import {
  Autocomplete,
  CircularProgress,
  TextField,
  Tooltip,
} from '@northvolt/ui'
import { useDebounce } from '@uidotdev/usehooks'
import React, { useEffect } from 'react'

import type { FilterOptionsState } from '@mui/material'
import { useSearchDatasetsByText } from 'client/atlas/atlas'
import type { Dataset, DatasetLight } from 'client/model'
import DatasetChip from './DatasetChip'
import DatasetSelectItem from './DatasetSelectItem'

interface DatasetSearchAndSelectProps {
  selectedDatasets: DatasetLight[]
  setSelectedDatasets: (datasets: DatasetLight[]) => void
  disabled?: boolean
  multiple?: boolean
  label?: string
  tooltip?: string
}

const DatasetSearchAndSelect: React.FC<DatasetSearchAndSelectProps> = ({
  selectedDatasets,
  setSelectedDatasets,
  disabled,
  multiple,
  label = 'Select Datasets',
  tooltip = 'Free text search for datasets',
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 300)
  const datasetLoader = useSearchDatasetsByText()

  useEffect(() => {
    if (debouncedSearchText) {
      datasetLoader.mutate({ params: { text: debouncedSearchText } })
    } else {
      datasetLoader.reset()
    }
  }, [debouncedSearchText])

  const getOptions = () => {
    const searchOptions = datasetLoader.data?.data?.items
      ? datasetLoader.data.data.items
      : []
    const selectedOptions = selectedDatasets ? selectedDatasets : []
    const distinctOptions = new Set([...searchOptions, ...selectedOptions])
    return Array.from(distinctOptions)
  }

  return (
    <Tooltip title={tooltip} placement='top'>
      <Autocomplete
        size='small'
        options={getOptions()}
        filterOptions={(options: Dataset[], _: FilterOptionsState<any>) =>
          options
        } // Don't do any additional filtering in the frontend
        loading={datasetLoader.isLoading}
        onChange={(_: any, newValue: any) => {
          if (multiple) {
            setSelectedDatasets(newValue)
          } else {
            setSelectedDatasets(newValue ? [newValue] : [])
          }
        }}
        onInputChange={(_event, newInputValue) => {
          setSearchText(newInputValue)
        }}
        value={
          multiple
            ? selectedDatasets
            : selectedDatasets?.length > 0
              ? selectedDatasets[0]
              : null
        }
        getOptionLabel={(dataset: DatasetLight) => dataset.name}
        isOptionEqualToValue={(dataset: any, other: any) =>
          dataset.id === other.id
        }
        renderOption={(props, dataset: DatasetLight) => {
          const myProps: any = props // annoying...
          const { key, ...rest } = myProps
          return (
            <DatasetSelectItem
              dataset={dataset}
              listItemProps={rest}
              searchText={searchText}
              key={dataset.id}
            />
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {datasetLoader.isLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value: any, getTagProps: any) =>
          value.map((dataset: Dataset, index: number) => (
            <DatasetChip
              dataset={dataset}
              key={index}
              chipProps={getTagProps({ index })}
            />
          ))
        }
        autoHighlight
        autoComplete
        fullWidth
        filterSelectedOptions
        noOptionsText='Empty or invalid search query (please type something).'
        disabled={disabled}
        multiple={!!multiple}
        disableCloseOnSelect={multiple}
      />
    </Tooltip>
  )
}

export default DatasetSearchAndSelect
