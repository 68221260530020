type ApiState<T = any> = {
  data: T | null
  // loading: boolean
  error: Error | null
  success: boolean
}

type ApiContextType = {
  execute: <T = any, P = any>(
    endpoint: string,
    params?: P,
    options?: RequestInit,
    retryAttempts?: number,
  ) => Promise<T>
}

type SearchParams = {
  text: string
  grain?: string
  limit?: number
  offset?: number
}

type SearchResult<T> = {
  items: T[]
  total: number
}

enum Actions {
  CREATE_STATE = 'create_state',
  ADD_FIELD = 'add_input',
  UPDATE_VALUE = 'update_value',
}

type Action = {
  type: Actions
  payload:
    | FormState
    | {
        [id: string]: FormElement['value']
      }
}

type InputTypes = 'text' | 'number' | 'date' | 'boolean' | 'select' | 'list'

type FormState = {
  [id: string]: FormElement
}

type FormElement = {
  value: any
  type: InputTypes
  label: string
  pristine?: boolean
  error?: string
}

type FormContextType = {
  form: FormState
  dispatchState: (form: FormState) => void
  dispatchValue: (id: string, value: any, label?: string) => void
}

export { Actions }
export type {
  Action,
  ApiContextType,
  ApiState,
  FormContextType,
  FormElement,
  FormState,
  InputTypes,
  SearchParams,
  SearchResult,
}
