/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

export type MaterialType = typeof MaterialType[keyof typeof MaterialType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialType = {
  Pack: 'Pack',
  Module: 'Module',
  Prismatic_Cell: 'Prismatic Cell',
  Prismatic_Jelly_Roll: 'Prismatic Jelly Roll',
  Anode_Sheet: 'Anode Sheet',
  Cathode_Sheet: 'Cathode Sheet',
  Anode_Pancake: 'Anode Pancake',
  Cathode_Pancake: 'Cathode Pancake',
  Pressed_Anode_Jumbo_Roll: 'Pressed Anode Jumbo Roll',
  Pressed_Cathode_Jumbo_Roll: 'Pressed Cathode Jumbo Roll',
  Coated_Anode_Jumbo_Roll: 'Coated Anode Jumbo Roll',
  Coated_Cathode_Jumbo_Roll: 'Coated Cathode Jumbo Roll',
  Anode_Slurry: 'Anode Slurry',
  Cathode_Slurry: 'Cathode Slurry',
  Anode_Binder: 'Anode Binder',
  Cathode_Binder: 'Cathode Binder',
  Cathode_Active_Material: 'Cathode Active Material',
  Any: 'Any',
} as const;
