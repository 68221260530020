import { Diagram2 } from '@northvolt/icons'
import { Button, Grid, Link, Stack, Typography } from '@northvolt/ui'

import { SignIn } from '@northvolt/snowflake'
import { track } from '@vercel/analytics'
import { motion } from 'framer-motion'
import mixpanel from 'mixpanel-browser'

export default function LoginForm() {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ minHeight: '100vh' }}>
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ alignItems: 'center' }}
        spacing={1}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}>
          <Stack spacing={1} marginBottom={{ xs: 10, md: 30 }}>
            <>
              <Diagram2
                data-animation='float'
                sx={{
                  alignSelf: 'center',
                  marginBottom: 2,
                  marginTop: 0,
                  textAlign: 'center',
                  width: '100px',
                  height: '100px',
                }}
              />
              <Typography textAlign='center' variant='headlineMedium'>
                Welcome to Verona
              </Typography>
              <Typography
                textAlign='center'
                sx={{ marginBottom: 4, textAlign: 'center' }}
                variant='bodyMedium'>
                Please log in to access the application.
              </Typography>

              <Button
                variant='outlined'
                color='secondary'
                sx={{ marginBottom: 2 }}
                onClick={() => {
                  mixpanel.track('login')
                  SignIn()
                  track('login')
                }}>
                Login
              </Button>

              <Link
                target='_blank'
                href='https://it.northvolt.com'
                sx={{
                  textAlign: 'center',
                  textDecoration: 'none',
                }}>
                I&apos;m unable to log in.
              </Link>
            </>
          </Stack>
        </motion.div>
      </Stack>
    </Grid>
  )
}
