// This file is beyond repair. Please consider starting over.
// @ts-nocheck
import { Box, type Theme, useTheme } from '@northvolt/ui'
import * as vg from '@uwdata/vgplot'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'

interface TableViewProps {
  columns: string[]
  width: number
  height: number
  $filterParam: any
  renderId?: number
}

const TableView: React.FC<TableViewProps> = ({
  columns,
  width,
  height,
  $filterParam,
  renderId,
}) => {
  const [tableView, setTableView] = useState<any>(null)
  const theme = useTheme()
  const tableRef = useRef<any>(null)

  useEffect(() => {
    if (width && height < Number.POSITIVE_INFINITY) {
      if (columns.length > 0) {
        const p = getTableView(columns, $filterParam, height, width)
        setTableView(p)
      } else {
        console.log('Unknown plot type')
      }
    } else {
      setTableView(vg.hspace(width))
    }
  }, [columns, width, height, renderId])

  useEffect(() => {
    if (tableRef.current && tableView) {
      tableRef.current.appendChild(tableView)
      styleTableView(tableView, theme)
      return () => {
        if (tableRef.current) {
          tableRef.current.removeChild(tableView)
        }
        // Remove the style element
        const style = document.querySelector(
          `style[data-for='${tableView.id}']`,
        )
        if (style) {
          document.head.removeChild(style)
        }
      }
    }
  }, [tableView, tableRef])

  return <Box ref={tableRef} sx={{ width: '100%' }} />
}

export default TableView

function getTableView(columns, $filterParam, height, width) {
  return vg.table({
    from: 'active_table',
    columns: columns,
    filterBy: $filterParam,
    width: width,
    height: height,
  })
}

// Lord forgive me for I have sinned
function styleTableView(tableView, theme: Theme) {
  const themeGrey = theme.palette.grey[800]
  const themeGreyLight = theme.palette.grey[900]

  // Create a new style element
  const style = document.createElement('style')

  // Set the innerHTML to the desired CSS
  style.innerHTML = `
    #${tableView.id} {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      max-width: initial;
      margin: 0;
      font-size: 13px;
      line-height: 15.6px;
      font-variant-numeric: tabular-nums;
    }

    #${tableView.id} table {
      display: table;
      width: 100%;
      table-layout: auto;
      border-collapse: separate;
      border-spacing: 0;
      min-height: 33px;
    }

    #${tableView.id} th, #${tableView.id} td {
      padding: 3px 6.5px 3px 0;
      border-bottom: solid 1px #eee;
      text-align: left;
      vertical-align: bottom;
    }

    #${tableView.id} thead tr th {
      position: sticky;
      top: 0;
      background: #fff;
      cursor: ns-resize;
      border-bottom: solid 1px #ccc;
      color: #111;
      padding-left: 10px;
      padding-right: 10px;
    }
    #${tableView.id} tbody tr {
      &:nth-child(2n) {
        background-color: ${themeGreyLight};
      }
      &:hover {
        background: ${themeGrey};
      }
      &:first-child td {
        padding-top: 4px;
      }
    }
  `
  style.setAttribute('data-for', tableView.id)
  document.head.appendChild(style)
}
