import type { Attribute } from 'client/model'
import type { Dispatch, JSX } from 'react'

/**
 * ValueFrom
 * A utility type to extract the values from a given object.
 * @typeParam T - The object to extract the values from.
 */
type ValueFrom<T> = T[keyof T]

/**
 * FilterState
 * The state holder of the filters, a subset of the filter component state.
 * @typeParam T - The type of the elements to be filtered.
 *
 */
type FilterState<T> = {
  values: ValueTypes
  attribute?: T
  operator: Operators
  slot?: number
  spec?: Spec
  isAttributeInvalid?: boolean
  isLoading?: boolean
  isVerified?: boolean
  id?: number
}

/**
 * ValueTypes
 * The various types of values the interface can display.
 */
interface ValueTypes {
  min_datetime?: string
  max_datetime?: string
  min_number?: number
  max_number?: number
  value?: string
  multiple?: boolean
  multiple_values?: { value: string }[]
}

/**
 * TypesOfValues
 * The possible types of values that can be displayed in the interface.
 */
class TypesOfValues implements ValueTypes {}

/**
 * FilterComponentState
 * The state holder of the component.
 * @typeParam T - The type of the elements to be filtered.
 */
type FilterComponentState<T> = {
  filters: FilterState<T>[]
  activeModal?: number
  activeOptions?: number
  activeFilter?: number
  search?: Promise<{ items: T[] }>
  canDispatch?: boolean
  elements: T[]
}

/**
 * FilterParams
 * The parameters received by the component, used to render the filter.
 * @typeParam T - The type of the elements to be filtered.
 * @typeParam K - The key of the element to be filtered.
 */
type FilterParams<T> = {
  grain: Grain
  set: (args: any) => void
  elements?: T[]
  targetKey?: keyof T
  disabled?: boolean
  initial?: Filter<T>[]
}

/**
 * Action
 * The structure of an action dispatched by the dispatcher.
 * @typeParam T - The type of the elements to be filtered.
 */
type Action<T> = {
  type: string
  payload: Partial<FilterComponentState<T> & FilterState<T>>
}

/**
 * Actions
 * The possible types of actions that can be executed in the interface,
 * dispatched and executed currently by the dispatcher.
 */
enum Actions {
  UPDATE_STATE = 'update_state',
  UPDATE_FILTER = 'update_filter',
  UPDATE_VALUES = 'update_values',
  UPDATE_MULTIPLE = 'update_multiple',
}
/**
 * OperatorHandler
 * The structure of any operator, used to render the filter.
 */
type OperatorHandler<T> = {
  value: Operators
  label: string
  icon: JSX.Element
  component?: InputParams<T>
}

/**
 * Operators
 * The possible types of operators.
 * It includes operations restricted to a given data type.
 */
enum Operators {
  DATETIME_RANGE = 'datetime_range',
  NUMBER_RANGE = 'number_range',
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  NOT_NULL = 'not_null',
  NULL = 'null',
  GREATER_THAN = 'greater_than',
  LESSER_THAN = 'lesser_than',
}

/**
 * InputParams
 * The received structure to render the value input.
 */
type InputParams<T> = (props: {
  slot: number
  dispatch: Dispatch<Action<T>>
  state: FilterComponentState<any>
  operator: Operators
  values: ValueTypes
  dataType: DataTypes
  attr?: Attr
  id?: string
}) => JSX.Element

/**
 * Attr
 * The possible roles for date and number inputs.
 */
enum Attr {
  VALUE = 'value',
  MIN_NUMBER = 'min_number',
  MAX_NUMBER = 'max_number',
  MIN_DATETIME = 'min_datetime',
  MAX_DATETIME = 'max_datetime',
}
/**
 * DataTypes
 * The various types of data the interface can display.
 */
enum DataTypes {
  number = 'number',
  double = 'number',
  long = 'number',
  int = 'number',
  bigint = 'number',
  boolean = 'boolean',
  string = 'text',
  timestamp = 'date',
  'array<string>' = 'text',
}
/**
 * DataTypes
 * The various types of data we receive from backend.
 */
type AttributeTypes =
  | 'number'
  | 'double'
  | 'long'
  | 'int'
  | 'bigint'
  | 'boolean'
  | 'string'
  | 'timestamp'
  | 'array<string>'

/**
 * DataTypesMap
 * A data type and its characteristics to be iterated in the interface,
 * according to the selected attribute type.
 */
type DataTypesHandler<T> = {
  [key in DataTypes]: {
    type: ValueFrom<DataTypes>
    component: React.ElementType<any>
    operators: OperatorHandler<T>[]
  }
}

/**
 * DateRangeInputValue
 * The internal structure used to store dates in the interface.
 * As you'll see below, this is sent and received in a flat object named Spec.
 */
type DateRangeInputValue = {
  from: HTMLInputElement | null
  to: HTMLInputElement | null
}

/**
 * Spec
 * The spec object that is returned by the
 * read_attribute_statistics endpoint.
 *
 */
type Spec = {
  attribute: Attribute
  count_distinct?: number
  count_null?: number
  count_star?: number // this is literally count star, as in count(*)
  count_value?: number
  datetime_max?: string
  datetime_min?: string
  double_max?: number
  double_min?: number
  long_max?: number
  long_min?: number
  string_distinct_values?: string[]
  string_max?: string
  string_min?: string
}

/**
 * Grain
 * The various types of grains the interface can display.
 */
type Grain =
  | 'Pack'
  | 'Module'
  | 'Prismatic Cell'
  | 'Prismatic Jelly Roll'
  | 'Anode Sheet'
  | 'Cathode Sheet'
  | 'Anode Pancake'
  | 'Cathode Pancake'
  | 'Pressed Anode Jumbo Roll'
  | 'Pressed Cathode Jumbo Roll'
  | 'Coated Anode Jumbo Roll'
  | 'Coated Cathode Jumbo Roll'
  | 'Anode Slurry'
  | 'Cathode Slurry'
  | 'Anode Binder'
  | 'Cathode Binder'
  | 'Cathode Active Material'
  | 'Any'

type Filter<T> = {
  attribute: T
  attribute_id: number
  id?: number
  type: Operators
  max_datetime?: string
  max_number?: number
  min_datetime?: string
  min_number?: number
  multiple?: boolean
  multiple_values?: { value: string }[]
  needs_quoting?: boolean
  sample_id?: number
  value?: string
}

export type {
  Action,
  AttributeTypes,
  DataTypesHandler,
  DateRangeInputValue,
  Filter,
  FilterComponentState,
  FilterParams,
  FilterState,
  Grain,
  InputParams,
  OperatorHandler,
  Spec,
  ValueFrom,
  ValueTypes,
}

export { Actions, Attr, DataTypes, Operators, TypesOfValues }
