import { Grid } from '@mui/material'
import { Button, TextField } from '@northvolt/ui'
import { useCreateSample } from 'client/atlas/atlas'
import {
  type AdhocFilter,
  MaterialType,
  type SampleCreate,
  type SampleLight,
} from 'client/model'
import GrainSelector from 'components/DataLoader/GrainSelector'
import Filter from 'components/Filter'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface NewSampleProps {
  samples?: SampleLight[]
  setSamples?: (samples: SampleLight[]) => void
  openOnCreate?: boolean
}

const NewSample: React.FC<NewSampleProps> = ({
  samples,
  setSamples,
  openOnCreate,
}) => {
  //@ts-ignore bad type
  const [sampleCreate, setSampleCreate] = useState<SampleCreate>(undefined)
  const [filters, setFilters] = useState<AdhocFilter[]>([])
  const [grain, setGrain] = useState<MaterialType>(MaterialType.Prismatic_Cell)
  const [created, setCreated] = useState<boolean>(false)
  const sampleMutation = useCreateSample()
  const navigate = useNavigate()
  const canSubmit =
    !sampleCreate ||
    !sampleCreate.grain ||
    !sampleCreate.name ||
    !sampleCreate.description ||
    !filters.length

  useEffect(() => {
    setSampleCreate({
      ...sampleCreate,
      filters: filters,
    })
  }, [filters])

  useEffect(() => {
    if (!created && sampleMutation.isSuccess) {
      if (openOnCreate) {
        navigate(`/atlas/samples/${sampleMutation.data.data.id}`)
      } else if (samples && setSamples) {
        setSamples([...samples, sampleMutation.data.data])
      }
      setCreated(true)
    }
  }, [sampleMutation.status])

  useEffect(() => {
    setSampleCreate({
      ...sampleCreate,
      grain: grain,
    })
  }, [grain])

  useEffect(() => {
    if (created) {
      //@ts-ignore this should not work
      setSampleCreate(undefined)
      setFilters([])
      setCreated(false)
      setGrain(MaterialType.Prismatic_Cell)
    }
  }, [created])

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={6}>
        <GrainSelector grain={grain} setGrain={setGrain} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Name'
          size='small'
          autoComplete='off'
          required
          fullWidth
          value={sampleCreate?.name ? sampleCreate.name : ''}
          onChange={e =>
            setSampleCreate({
              ...sampleCreate,
              name: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label='Description'
          size='small'
          multiline
          autoComplete='off'
          rows={4}
          required
          value={sampleCreate?.description ? sampleCreate.description : ''}
          onChange={e =>
            setSampleCreate({
              ...sampleCreate,
              description: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Filter elements={[]} set={setFilters} {...{ grain }} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          title={
            canSubmit
              ? 'Create Sample'
              : 'Please fill in all fields, and at least one filter.'
          }
          disabled={canSubmit}
          onClick={() => {
            sampleMutation.mutate({ data: sampleCreate })
          }}>
          Create Sample
        </Button>
      </Grid>
    </Grid>
  )
}

export default NewSample
