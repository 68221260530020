import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Adjusted useQueryParam hook to support defaultValue
export function useQueryParam(
  name: string,
  defaultValue: any,
  parseFunction: any,
) {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const paramValue = searchParams.get(name)

  // Initialize state with the defaultValue or parsed value
  const [value, setValue] = useState(() => {
    if (paramValue !== null) {
      const parsedValue = parseFunction ? parseFunction(paramValue) : paramValue
      // Check if parsedValue is undefined, indicating parsing failed, and use defaultValue
      return parsedValue !== undefined ? parsedValue : defaultValue
    }
    return defaultValue
  })

  // Function to update the query param
  const setQueryParam = useCallback(
    (newValue: any) => {
      const newSearchParams = new URLSearchParams(location.search)
      if (Array.isArray(newValue) && newValue.length === 0) {
        newSearchParams.delete(name)
      } else if (newValue !== undefined) {
        const serializedValue = Array.isArray(newValue)
          ? newValue.join(',')
          : newValue.toString()
        newSearchParams.set(name, serializedValue)
      } else {
        newSearchParams.delete(name)
      }
      navigate(`?${newSearchParams.toString()}`, { replace: true })
    },
    [name, navigate, location.search],
  )

  // Effect to react to changes in query params
  useEffect(() => {
    if (paramValue !== null) {
      const parsedValue = parseFunction ? parseFunction(paramValue) : paramValue
      setValue((oldValue: any) =>
        parsedValue !== undefined ? parsedValue : oldValue,
      )
    } else {
      setValue(defaultValue)
    }
  }, [paramValue])

  return [value, setQueryParam]
}

export const parseAsInteger = (value: string) => Number.parseInt(value, 10)

export const parseAsArrayOf = (parseFn: any) => (value: string) =>
  value ? value.split(',').filter(Boolean).map(parseFn) : []
export const parseAsBool = (value: string) => value.toLowerCase() === 'true'
export const parseAsBoolean = parseAsBool
export const parseAsString = (value: string) => value
